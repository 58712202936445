import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const customerPrices = {
  state: {
    prices: null,
  },

  mutations: {
    setPrices(state, payload) {
      state.prices = payload;
    },
  },

  actions: {
    getCustomerPrices({ commit, rootState, dispatch, state }, customerId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/" + customerId + "/customer_prices")
          .then((response) => {
            commit("setPrices", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createCustomerPrice({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.customerId + "/customer_prices",
            payload.data
          )
          .then((response) => {
            dispatch("getCustomerPrices", payload.customerId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts pievienots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izveidot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateCustomerPrice({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/customer_prices/" + payload.customerPriceId, {
            price: payload.price,
          })
          .then((response) => {
            dispatch("getCustomerPrices", payload.customerId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabotes",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteCustomerPrice({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/customer_prices/" + payload.customerPriceId)
          .then((response) => {
            dispatch("getCustomerPrices", payload.customerId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
  },
  getters: {
    customerPrices(state) {
      return state.prices;
    },
  },
};

export default customerPrices;
