import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const categoryOptions = {
  state: {
    categoryOptionForEdit: null,
    categoryOptionInputData: null,
  },

  mutations: {
    setCategoryOptionForEdit(state, categoryOption) {
      state.categoryOptionForEdit = categoryOption;
    },
    clearCategoryOptionForEdit(state) {
      state.categoryOptionForEdit = null;
    },
    setInputData(state, payload) {
      state.categoryOptionInputData = payload;
    },
  },

  actions: {
    getCategoryOptionInputData({ commit, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/category_options/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewCategoryOption({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.categoryId + "/category_options",
            payload.data
          )
          .then((response) => {
            dispatch("getSingleCategory", payload.categoryId);
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateCategoryOption({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/category_options/" + payload.id, payload)
          .then((response) => {
            dispatch("clearCategoryOptionForEdit");
            dispatch("getCategories");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteCategoryOption(
      { commit, rootState, dispatch, state },
      categoryOption
    ) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/category_options/" + categoryOption.id)
          .then((response) => {
            dispatch("getSingleCategory", categoryOption.category_id);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    setSelectedCategory({ commit }, category) {
      commit("setSelectedCategory", category);
    },
    clearSelectedCategory({ commit }) {
      commit("clearSelectedCategory");
    },
    setCategoryOptionForEdit({ commit }, categoryOption) {
      commit("setCategoryOptionForEdit", categoryOption);
    },
    clearCategoryOptionForEdit({ commit }) {
      commit("clearCategoryOptionForEdit");
    },
  },
  getters: {
    categoryOptionForEdit(state) {
      return state.categoryOptionForEdit;
    },
    categoryOptionInputData(state) {
      return state.categoryOptionInputData;
    },
  },
};

export default categoryOptions;
