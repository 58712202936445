import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const customerContacts = {
  state: {
    showAddCustomerContactForm: false,
    editableCustomerContact: {},
  },

  mutations: {
    setEditableCustomerContact(state, contact) {
      state.editableCustomerContact = contact;
    },
    clearEditableCustomerContact(state) {
      state.showAddCustomerContactForm = false;
      state.editableCustomerContact = {};
    },
  },

  actions: {
    createNewCustomerContact({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.customerId + "/customer_contacts",
            payload.data
          )
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getCustomer", payload.customerId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts pievienots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izveidot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateCustomerContact({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/customer_contacts/" + payload.data.id,
            payload.data
          )
          .then((response) => {
            dispatch("clearEditableCustomerContact");
            dispatch("getCustomer", payload.customerId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteCustomerContact({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/customer_contacts/" + payload.contactId)
          .then((response) => {
            dispatch("getCustomer", payload.customerId);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            if (error.response.status === 403) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Šo ierakstu dzēsts ir aizliegts",
              });
            }
            reject(error);
          });
      });
    },
    setEditableCustomerContact({ commit }, contact) {
      commit("setEditableCustomerContact", contact);
    },
    clearEditableCustomerContact({ commit }) {
      commit("clearEditableCustomerContact");
    },
  },
  getters: {
    editableCustomerContact(state) {
      return state.editableCustomerContact;
    },
  },
};

export default customerContacts;
