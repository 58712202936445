import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const branches = {
  state: {
    branchInputData: null,
    branch: null,
    branchErrorMessages: {
      email: null,
      reg_nr: null,
      notes: null,
    },
  },

  mutations: {
    setBranch(state, payload) {
      state.branch = payload;
    },
    setBranchInputData(state, payload) {
      state.branchInputData = payload;
    },
    clearBranch(state) {
      state.branch = null;
    },
    setBranchErrorMessages(state, error) {
      state.branchErrorMessages = error;
    },
    clearBranchErrorMessages(state) {
      state.branchErrorMessages = {
        email: null,
        reg_nr: null,
        notes: null,
      };
    },
  },

  actions: {
    createNewBranch({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.customerId + "/branches",
            payload.data
          )
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getCustomer", payload.customerId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts pievienots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izveidot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    getBranchInputData({ commit, rootState, dispatch }, customerId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/" + customerId + "/branches/create")
          .then((response) => {
            commit("setBranchInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    getSingleBranch({ commit, rootState, dispatch, state }, branchId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/branches/" + branchId)
          .then((response) => {
            commit("setBranch", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateBranch({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/branches/" + payload.id, payload)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleBranch", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setBranchErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteBranch({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/branches/" + payload.branchId)
          .then((response) => {
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            vm.config.globalProperties.$router.push({
              path: "/customers/" + payload.customerId,
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    clearBranch({ commit }) {
      commit("clearBranch");
    },
    clearBranchErrorMessages({ commit }) {
      commit("clearBranchErrorMessages");
    },
  },
  getters: {
    branch(state) {
      return state.branch;
    },
    branchInputData(state) {
      return state.branchInputData;
    },
    branchErrorMessages(state) {
      return state.branchErrorMessages;
    },
  },
};

export default branches;
