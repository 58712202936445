import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const categoryOptionItems = {
  state: {
    showAddCategoryOptionItemForm: false,
    selectedOption: {},
    categoryOptionItemForEdit: null,
  },

  mutations: {
    showAddCategoryOptionItemForm(state, option) {
      state.showAddCategoryOptionItemForm = true;
      state.selectedOption = option;
    },
    hideAddCategoryOptionItemForm(state) {
      state.showAddCategoryOptionItemForm = false;
      state.selectedOption = {};
    },
    setCategoryOptionItemForEdit(state, categoryOptionItem) {
      state.categoryOptionItemForEdit = categoryOptionItem;
    },
    clearCategoryOptionItemForEdit(state) {
      state.categoryOptionItemForEdit = null;
    },
  },

  actions: {
    createNewCategoryOptionItem(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.optionId + "/category_option_items",
            payload.data
          )
          .then((response) => {
            dispatch("getSingleCategory", payload.categoryId);
            dispatch("getCategories");
            commit("hideAddCategoryOptionItemForm");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateCategoryOptionItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/category_option_items/" + payload.item.id,
            payload.item
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("getSingleCategory", payload.categoryId);
            commit("clearCategoryOptionItemForEdit");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteCategoryOptionItem({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/category_option_items/" + payload.itemId)
          .then((response) => {
            dispatch("getSingleCategory", payload.categoryId);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    showAddCategoryOptionItemForm({ commit }, option) {
      commit("showAddCategoryOptionItemForm", option);
    },
    hideAddCategoryOptionItemForm({ commit }) {
      commit("hideAddCategoryOptionItemForm");
    },
    setCategoryOptionItemForEdit({ commit }, categoryOptionItem) {
      commit("setCategoryOptionItemForEdit", categoryOptionItem);
    },
    clearCategoryOptionItemForEdit({ commit }) {
      commit("clearCategoryOptionItemForEdit");
    },
  },
  getters: {
    showAddCategoryOptionItemForm(state) {
      return state.showAddCategoryOptionItemForm;
    },
    categoryOptionItemForEdit(state) {
      return state.categoryOptionItemForEdit;
    },
    selectedOption(state) {
      return state.selectedOption;
    },
  },
};

export default categoryOptionItems;
