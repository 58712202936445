<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <h2>Kredītrēķins</h2>
            </div>
        </template>

        <template v-slot:content>
            <div>
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">

                        <template v-if="creditNote && showData">
                            <CreditNoteDetails :item="creditNote"/>
                        </template>

                        <template v-if="!creditNote.accepted">
                            <div class="flex w-full">
                                <button @click="acceptCreditNote" type="button" class="inline-flex items-center px-3 py-2 border
                      border-transparent text-sm leading-4 font-medium rounded-md text-white
                          bg-main4 hover:bg-main3 transition duration-150 ease-out hover:ease-in mb-3 ml-auto"
                                >
                                    Apstiprināt kredītrēķinu
                                </button>
                            </div>
                        </template>

                        <template v-if="creditNote.accepted">
                            <div class="flex w-full">
                                <button @click="revertAcceptCreditNote" type="button" class="inline-flex items-center px-3 py-2 border
                      border-transparent text-sm leading-4 font-medium rounded-md text-white
                          bg-red-500 hover:bg-red-600 transition duration-150 ease-out hover:ease-in mb-3 ml-auto"
                                >
                                    Atgriezt sagatavē
                                </button>
                            </div>
                        </template>


                        <template v-if="creditNote && creditNote.customer && showData">
                            <AllCreditNoteItems
                                :creditNote="creditNote"
                            />
                        </template>


                    </ul>
                </div>
            </div>
        </template>
    </Content>
</template>

<script>
import {mapGetters} from "vuex";
import {defineAsyncComponent} from 'vue'

const CreditNoteDetails = defineAsyncComponent(() =>
    import('@/components/CreditNotes/CreditNoteDetails'))
const AllCreditNoteItems = defineAsyncComponent(() =>
    import('@/components/CreditNotes/AllCreditNoteItems'))

export default {
    name: "CreditNote",
    components: {
        CreditNoteDetails,
        AllCreditNoteItems,
    },
    data() {
        return {
            showData: false,
        }
    },
    created() {
        this.$store.dispatch("getSingleCreditNote", this.$route.params.creditNoteId)
            .then(() => {
                this.showData = true
            })
    },
    computed: {
        ...mapGetters({
            creditNote: "creditNote",
        }),
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },
        acceptCreditNote() {
            if(confirm('Vai esi drošs?')){
                this.$Progress.start()
                this.$store.dispatch("acceptCreditNote", this.creditNote.id)
                    .then(() => {
                        this.showData = true
                    })
            }
        },
        revertAcceptCreditNote() {
            if(confirm('Vai esi drošs?')){
                this.$Progress.start()
                this.$store.dispatch("revertCreditNote", this.creditNote.id)
                    .then(() => {
                        this.showData = true
                    })
            }
        }
    },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>