import { vm } from "@/main";
import axios from "axios";
import contact from "./Contacts/contact";
import customerDiscounts from "./CustomerDetails/customerDiscounts";
import customerPaymentDetails from "./CustomerDetails/customerPaymentDetails";
import assignUserToContact from "./Settings/assignUserToContact";
import customerPrices from "@/store/Customers/Prices/customerPrices";
import customerOrders from "@/store/Customers/Orders/customerOrders";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const customer = {
  modules: {
    customerPaymentDetails,
    customerDiscounts,
    assignUserToContact,
    contact,
    customerPrices,
    customerOrders,
  },
  state: {
    customer: null,
  },

  mutations: {
    setCustomer(state, customer) {
      state.customer = customer;
    },
    clearCustomer(state) {
      state.customer = null;
    },
  },

  actions: {
    getCustomer({ commit, rootState, dispatch, state }, customerId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/customers/" + customerId)
          .then((response) => {
            commit("setCustomer", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateCustomer({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/customers/" + payload.id, payload)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getCustomer", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              commit("setCustomerErrorMessages", error.response.data.errors);
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteCustomer({ commit, rootState, dispatch, state }, customerId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/customers/" + customerId)
          .then((response) => {
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            vm.config.globalProperties.$router.push("/customers");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    clearCustomer({ commit }) {
      commit("clearCustomer");
    },
  },
  getters: {
    customer(state) {
      return state.customer;
    },
  },
};

export default customer;
