import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const warehouses = {
  state: {
    warehouses: null,
    warehouseInputData: null,
  },

  mutations: {
    setWarehouses(state, warehouses) {
      state.warehouses = warehouses;
    },
    setInputData(state, payload) {
      state.warehouseInputData = payload;
    },
  },

  actions: {
    getWarehouses({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/company_settings/warehouses?page=" +
              page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setWarehouses", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },
    getWarehouseInputData({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/company_settings/warehouses/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    createNewWarehouse({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/company_settings/warehouses", payload)
          .then((response) => {
            dispatch("getWarehouses");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateWarehouse({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/company_settings/warehouses/" + payload.id,
            payload
          )
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch("getWarehouses");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteWarehouse({ commit, rootState, dispatch, state }, warehouseId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/company_settings/warehouses/" + warehouseId)
          .then((response) => {
            dispatch("getWarehouses");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    warehouses(state) {
      return state.warehouses;
    },
    warehouseInputData(state) {
      return state.warehouseInputData;
    },
  },
};

export default warehouses;
