import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/Login.vue"),
    meta: {
      // requiresVisitor: true
    },
  },
  {
    path: "/customers",
    name: "customers",
    component: () =>
      import(
        /* webpackChunkName: "Customers" */ "../views/Customers/Customers.vue"
      ),
    meta: {
      title: "Klienti",
      requiresAuth: true,
      requiresPermission: "view_customers",
    },
  },
  {
    path: "/customers/:customerId",
    name: "customer",
    component: () =>
      import(
        /* webpackChunkName: "Customer" */ "../views/Customers/Customer.vue"
      ),
    meta: {
      title: "Klienti",
      requiresAuth: true,
      requiresPermission: "view_customers",
    },
  },
  {
    path: "/customers/:customerId/prices",
    name: "customer.prices",
    component: () =>
      import(
        /* webpackChunkName: "CustomerPrices" */ "../views/Customers/Prices/CustomerPrices.vue"
      ),
    meta: {
      title: "Klienti",
      requiresAuth: true,
      requiresPermission: "manage_customers",
    },
  },
  {
    path: "/customers/:customerId/orders",
    name: "customer.orders",
    component: () =>
      import(
        /* webpackChunkName: "CustomerOrders" */ "../views/Customers/Orders/CustomerOrders.vue"
      ),
    meta: {
      title: "Klienta pasūtījumi",
      requiresAuth: true,
      requiresPermission: "manage_customers",
    },
  },
  {
    path: "/customers/:customerId/branches/:branchId",
    name: "branch",
    component: () =>
      import(/* webpackChunkName: "Branch" */ "../views/Customers/Branch.vue"),
    meta: {
      title: "Klienti",
      requiresAuth: true,
      requiresPermission: "view_customers",
    },
  },
  {
    path: "/contacts/:contactId",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "Branch" */ "../views/Customers/Contact.vue"),
    meta: {
      requiresAuth: true,
      requiresPermission: "view_customers",
    },
  },
  {
    path: "/stock/stock_items",
    name: "stock_items",
    component: () =>
      import(
        /* webpackChunkName: "StockItems" */ "../views/Stock/StockItems/StockItems.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_stock",
    },
  },
  {
    path: "/stock/suppliers",
    name: "suppliers",
    component: () =>
      import(
        /* webpackChunkName: "Suppliers" */ "../views/Stock/Suppliers/Suppliers.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_suppliers",
    },
  },
  {
    path: "/stock/suppliers/:supplierId",
    name: "supplier",
    component: () =>
      import(
        /* webpackChunkName: "Suppliers" */ "../views/Stock/Suppliers/Supplier.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_suppliers",
    },
  },
  {
    path: "/stock/suppliers/:supplierId/categories",
    name: "supplier_categories",
    component: () =>
      import(
        /* webpackChunkName: "Suppliers" */ "../views/Stock/Suppliers/Categories/SupplierCategories.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_suppliers",
    },
  },
  {
    path: "/stock/procurements",
    name: "procurements",
    component: () =>
      import(
        /* webpackChunkName: "Procurements" */ "../views/Stock/Procurement/Procurements.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_procurement",
    },
  },
  {
    path: "/stock/procurements/create",
    name: "procurements.create",
    component: () =>
      import(
        /* webpackChunkName: "ProcurementCreate" */ "../views/Stock/Procurement/CreateProcurement.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_procurement",
    },
  },
  {
    path: "/stock/procurements/:procurementId",
    name: "procurements.show",
    component: () =>
      import(
        /* webpackChunkName: "Procurement" */ "../views/Stock/Procurement/Procurement.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_procurement",
    },
  },
  {
    path: "/stock/procurement_return_acts",
    name: "procurement_return_acts",
    component: () =>
      import(
        /* webpackChunkName: "ProcurementReturnActs" */ "../views/Stock/ProcurementReturnActs/ProcurementReturnActs.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_procurement",
    },
  },
  {
    path: "/stock/procurement_return_acts/:id",
    name: "procurement_return_acts.show",
    component: () =>
      import(
        /* webpackChunkName: "Procurement" */ "../views/Stock/ProcurementReturnActs/ProcurementReturnAct.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_procurement",
    },
  },

  {
    path: "/stock/inventory_items",
    name: "inventory_items",
    component: () =>
      import(
        /* webpackChunkName: "Inventory items" */ "../views/Stock/InventoryItems/InventoryItems.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_inventory_items",
    },
  },
  {
    path: "/stock/inventory_items/create",
    name: "inventory_items.create",
    component: () =>
      import(
        /* webpackChunkName: "CreateInventoryTtem" */ "../views/Stock/InventoryItems/CreateInventoryItem.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_inventory_items",
    },
  },
  {
    path: "/stock/inventory_items/:itemUUID",
    name: "inventory_item",
    component: () =>
      import(
        /* webpackChunkName: "Inventory item" */ "../views/Stock/InventoryItems/InventoryItem.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "view_inventory_items",
    },
  },
  {
    path: "/stock/tehnical_documents",
    name: "tehnical_documents",
    component: () =>
      import(
        /* webpackChunkName: "Inventory items" */ "../views/Stock/TehnicalDocuments/TehnicalDocuments.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "manage_technical_documents",
    },
  },
  {
    path: "/stock/tehnical_documents/:documentId",
    name: "technical_documents.show",
    component: () =>
      import(
        /* webpackChunkName: "Inventory items" */ "../views/Stock/TehnicalDocuments/TehnicalDocument.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "manage_technical_documents",
    },
  },
  {
    path: "/stock/tehnical_documents/create",
    name: "tehnical_documents.create",
    component: () =>
      import(
        /* webpackChunkName: "Inventory items" */ "../views/Stock/TehnicalDocuments/CreateTehnicalDocuments.vue"
      ),
    meta: {
      title: "Noliktava",
      requiresAuth: true,
      requiresPermission: "manage_technical_documents",
    },
  },
  {
    path: "/stock/stock_checks",
    name: "stock_checks",
    component: () =>
      import(
        /* webpackChunkName: "Inventory items" */ "../views/Stock/StockChecks/StockChecks.vue"
      ),
    meta: {
      title: "Inventerizācija",
      requiresAuth: true,
      requiresPermission: "manage_xml",
    },
  },
  {
    path: "/stock/reports",
    name: "stock_reports",
    component: () =>
        import(
            /* webpackChunkName: "Inventory reports" */ "../views/Stock/Reports/StockReports.vue"
            ),
    meta: {
      title: "Atskaites",
      requiresAuth: true,
      requiresPermission: "view_stock",
    },
  },
  {
    path: "/stock/stock_checks/:id",
    name: "stock_checks.show",
    component: () =>
      import(
        /* webpackChunkName: "Inventory items" */ "../views/Stock/StockChecks/StockCheck.vue"
      ),
    meta: {
      title: "Inventerizācija",
      requiresAuth: true,
      requiresPermission: "manage_xml",
    },
  },
  {
    path: "/stock/stock_checks/create",
    name: "stock_checks.create",
    component: () =>
      import(
        /* webpackChunkName: "Inventory items" */ "../views/Stock/StockChecks/CreateStockCheck.vue"
      ),
    meta: {
      title: "Inventerizācija",
      requiresAuth: true,
      requiresPermission: "manage_xml",
    },
  },

  {
    path: "/orders",
    name: "orders",
    component: () =>
      import(/* webpackChunkName: "Orders" */ "../views/Orders/Orders.vue"),
    meta: {
      title: "Pasūtījumi",
      requiresAuth: true,
      requiresPermission: "view_orders",
    },
  },
  {
    path: "/orders/create",
    name: "orders.create",
    component: () =>
      import(
        /* webpackChunkName: "OrderCreate" */ "../views/Orders/CreateOrder.vue"
      ),
    meta: {
      title: "Pasūtījumi",
      requiresAuth: true,
      requiresPermission: "manage_orders",
    },
  },
  {
    path: "/orders/:orderId",
    name: "orders.view",
    component: () =>
      import(/* webpackChunkName: "Order" */ "../views/Orders/Order.vue"),
    meta: {
      title: "Pasūtījumi",
      requiresAuth: true,
      requiresPermission: "view_orders",
    },
  },

  {
    path: "/catalog",
    name: "catalog",
    component: () =>
      import(/* webpackChunkName: "Catalog" */ "../views/Settings/Catalog.vue"),
    meta: {
      title: "Katalogs",
      requiresAuth: true,
      requiresPermission: "view_catalog_items",
    },
  },
  {
    path: "/catalog/:catalogItemId",
    name: "catalog_item",
    component: () =>
      import(
        /* webpackChunkName: "CatalogItem" */ "../views/Settings/CatalogItem.vue"
      ),
    meta: {
      title: "Katalogs",
      requiresAuth: true,
      requiresPermission: "view_catalog_items",
    },
  },

  {
    path: "/catalog_item_units",
    name: "units",
    component: () =>
      import(/* webpackChunkName: "Units" */ "../views/CatalogItemUnits/CatalogItemUnits.vue"),
    meta: {
      title: "Vienības",
      requiresAuth: true,
      requiresPermission: "view_catalog_items",
    },
  },
  {
    path: "/catalog_item_units/:number",
    name: "unit",
    component: () =>
      import(/* webpackChunkName: "Unit" */ "../views/CatalogItemUnits/CatalogItemUnit.vue"),
    meta: {
      title: "Vienības",
      requiresAuth: true,
      requiresPermission: "view_catalog_items",
    },
  },

  {
    path: "/categories",
    name: "categories",
    component: () =>
      import(
        /* webpackChunkName: "Categories" */ "../views/Settings/Categories/Categories.vue"
      ),
    meta: {
      title: "Preču grupas",
      requiresAuth: true,
      requiresPermission: "manage_categories",
    },
  },
  {
    path: "/categories/:categoryId",
    name: "category",
    component: () =>
      import(
        /* webpackChunkName: "Categories" */ "../views/Settings/Categories/Category.vue"
      ),
    meta: {
      title: "Preču grupas",
      requiresAuth: true,
      requiresPermission: "manage_categories",
    },
  },
  {
    path: "/categories/:categoryId/products",
    name: "category_products",
    component: () =>
      import(
        /* webpackChunkName: "Categories" */ "../views/Settings/Categories/Products/CategoryProducts.vue"
      ),
    meta: {
      title: "Preču grupas",
      requiresAuth: true,
      requiresPermission: "manage_categories",
    },
  },
  {
    path: "/params",
    name: "params",
    component: () =>
      import(
        /* webpackChunkName: "CatalogParams" */ "../views/Settings/CatalogParams.vue"
      ),
    meta: {
      title: "Parametri",
      requiresAuth: true,
      requiresPermission: "manage_catalog_params",
    },
  },
  {
    path: "/banks",
    name: "Banks",
    component: () =>
      import(/* webpackChunkName: "Banks" */ "../views/Settings/Banks.vue"),
    meta: {
      title: "Bankas",
      requiresAuth: true,
      requiresPermission: "manage_banks",
    },
  },
  {
    path: "/company_forms",
    name: "CompanyForms",
    component: () =>
      import(
        /* webpackChunkName: "CompanyForms" */ "../views/Settings/CompanyForms.vue"
      ),
    meta: {
      title: "Uzņēmumu veidi",
      requiresAuth: true,
      requiresPermission: "manage_company_forms",
    },
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: () =>
      import(
        /* webpackChunkName: "Warehouses" */ "../views/Settings/Warehouses.vue"
      ),
    meta: {
      title: "Noliktavas",
      requiresAuth: true,
      requiresPermission: "manage_warehouses",
    },
  },
  {
    path: "/measure_units",
    name: "measure_units",
    component: () =>
      import(
        /* webpackChunkName: "MeasureUnits" */ "../views/Settings/MeasureUnits.vue"
      ),
    meta: {
      title: "Mērvienības",
      requiresAuth: true,
      requiresPermission: "manage_measure_units",
    },
  },

  {
    path: "/invoices",
    name: "invoices",
    component: () =>
      import(
        /* webpackChunkName: "Invoices" */ "../views/Invoices/Invoices.vue"
      ),
    meta: {
      title: "Rēķini",
      requiresAuth: true,
      requiresPermission: "view_invoices",
    },
  },
  {
    path: "/invoices/:orderId",
    name: "invoice",
    component: () =>
      import(/* webpackChunkName: "Invoice" */ "../views/Invoices/Invoice.vue"),
    meta: {
      title: "Rēķini",
      requiresAuth: true,
      requiresPermission: "view_invoices",
    },
  },
  {
    path: "/invoices/reports",
    name: "invoices_reports",
    component: () =>
      import(
        /* webpackChunkName: "InvoiceableCustomers" */ "../views/Invoices/InvoicesReports.vue"
      ),
    meta: {
      title: "Rēķini",
      requiresAuth: true,
      requiresPermission: "manage_invoice_reports",
    },
  },
  {
    path: "/invoices/export",
    name: "invoices_export",
    component: () =>
      import(
        /* webpackChunkName: "InvoicesExport" */ "../views/Invoices/InvoicesExport.vue"
      ),
    meta: {
      title: "Rēķini",
      requiresAuth: true,
      requiresPermission: "manage_invoices",
    },
  },
  {
    path: "/invoices/create",
    name: "create_invoice",
    component: () =>
      import(
        /* webpackChunkName: "CreateInvoice" */ "../views/Invoices/CreateInvoice.vue"
      ),
    meta: {
      title: "Rēķini",
      requiresAuth: true,
      requiresPermission: "manage_invoices",
    },
  },
  {
    path: "/invoices/credit_notes",
    name: "credit_notes",
    component: () =>
        import(
            /* webpackChunkName: "CreditNotes" */ "../views/Invoices/CreditNotes.vue"
            ),
    meta: {
      title: "Kredītrēķini",
      requiresAuth: true,
      requiresPermission: "manage_invoices",
    },
  },
  {
    path: "/invoices/credit_notes/:creditNoteId",
    name: "credit_note",
    component: () =>
        import(/* webpackChunkName: "CreditNote" */ "../views/Invoices/CreditNote.vue"),
    meta: {
      title: "Kredītrēķins",
      requiresAuth: true,
      requiresPermission: "manage_invoices",
    },
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/Users/Users.vue"),
    meta: {
      title: "Lietotāji",
      requiresAuth: true,
      requiresPermission: "manage_users",
    },
  },
  {
    path: "/users/edit/:userId",
    name: "users.edit",
    component: () =>
      import(/* webpackChunkName: "EditUser" */ "../views/Users/EditUser.vue"),
    meta: {
      title: "Lietotāji",
      requiresAuth: true,
      requiresPermission: "manage_users",
    },
  },
  {
    path: "/celebrations",
    name: "celebrations",
    component: () =>
        import(/* webpackChunkName: "Roles" */ "../views/Celebrations/Celebrations.vue"),
    meta: {
      title: "Svētki",
      requiresAuth: true,
      requiresPermission: "manage_roles",
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: () =>
      import(/* webpackChunkName: "Roles" */ "../views/Roles/Roles.vue"),
    meta: {
      title: "Lomas",
      requiresAuth: true,
      requiresPermission: "manage_roles",
    },
  },
  {
    path: "/roles/:roleId",
    name: "roles.show",
    component: () =>
      import(/* webpackChunkName: "Role" */ "../views/Roles/Role.vue"),
    meta: {
      title: "Lomas",
      requiresAuth: true,
      requiresPermission: "manage_roles",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("setCurrentPage", to.path);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "Login" });
    } else if (to.matched.some((record) => record.meta.requiresPermission)) {
      if (!store.getters.user) {
        store.dispatch("getUser");
        store.watch(store.getters.userFunction, function() {
          if (
            !store.getters.user.permissions.includes(to.meta.requiresPermission)
          ) {
            next({ name: "home" });
          } else {
            next();
          }
        });
      } else if (
        !store.getters.user.permissions.includes(to.meta.requiresPermission)
      ) {
        next({ name: "home" });
      } else {
        next();
      }
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({ name: "home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
