import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const catalogParamValues = {
  actions: {
    createNewParamValue({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/" +
              payload.catalog_param_id +
              "/catalog_param_values",
            payload
          )
          .then((response) => {
            dispatch("getParams");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              commit("setParamValueErrorMessages", error.response.data.errors);
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteParamValue({ commit, rootState, dispatch, state }, paramValueId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/catalog_param_values/" + paramValueId)
          .then((response) => {
            dispatch("getParams");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
};

export default catalogParamValues;
