import axios from "axios";
import {vm} from '@/main'
import creditNote from "../../views/Invoices/CreditNote";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL

const creditNotes = {

    state: {
        creditNotes: null,
        creditNote: null
    },

    mutations: {
        setCreditNotes(state, creditNotes) {
            state.creditNotes = creditNotes
        },
        setCreditNote(state, creditNote) {
            state.creditNote = creditNote
        },
    },

    actions: {
        getCreditNotes({commit, rootState, dispatch, state}, page) {
            commit("setDataLoading", true)

            return new Promise((resolve, reject) => {

                axios  .get(baseUrl + "/api/credit_notes?page=" + page + "&q=" + rootState.search)
                    .then(response => {
                        commit('setCreditNotes', response.data)
                        commit("setDataLoading", false)
                        resolve(response)
                    })
                    .catch(error => {

                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        commit("setDataLoading", false)
                        reject(error)
                    })
            })
        },

        getSingleCreditNote({commit, rootState, dispatch, state}, creditNoteId) {
            commit("setDataLoading", true)

            return new Promise((resolve, reject) => {

                axios  .get(baseUrl + "/api/credit_notes/" + creditNoteId)
                    .then(response => {
                        commit('setCreditNote', response.data)
                        commit("setDataLoading", false)
                        resolve(response)
                    })
                    .catch(error => {

                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        commit("setDataLoading", false)
                        reject(error)
                    })
            })
        },

        createNewCreditNote({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios.post(
                        baseUrl + "/api/credit_notes/" + payload.order_id,
                        payload.data
                    )
                    .then((response) => {
                        dispatch("getSingleOrder", payload.order_id);
                        dispatch("removeAllFormsForDisplay");
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Ieraksts izveidots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("setCatalogItemErrorMessages", error.response.data.errors);
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        updateCreditNote({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios.patch(
                    baseUrl + "/api/credit_notes/" + payload.id,
                    payload
                )
                    .then((response) => {
                        dispatch("getSingleCreditNote", payload.id);
                        dispatch("removeAllFormsForDisplay");
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Ieraksts izveidots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("setCatalogItemErrorMessages", error.response.data.errors);
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        acceptCreditNote({commit, rootState, dispatch, state}, creditNoteId) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios.get(baseUrl + "/api/accept_credit_note/" + creditNoteId)
                    .then((response) => {
                        dispatch("getSingleCreditNote", creditNoteId);
                        dispatch("removeAllFormsForDisplay");
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Ieraksts saglabāts",
                        });
                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("setCatalogItemErrorMessages", error.response.data.errors);
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        revertCreditNote({commit, rootState, dispatch, state}, creditNoteId) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios.get(baseUrl + "/api/revert_accepted_credit_note/" + creditNoteId)
                    .then((response) => {
                        dispatch("getSingleCreditNote", creditNoteId);
                        dispatch("removeAllFormsForDisplay");
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Ieraksts saglabāts",
                        });
                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("setCatalogItemErrorMessages", error.response.data.errors);
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        deleteCreditNote({commit, rootState, dispatch, state}, creditNoteId) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios.delete(baseUrl + "/api/credit_notes/" + creditNoteId)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        dispatch("removeAllFormsForDisplay");
                        vm.config.globalProperties.$router.push({name: "credit_notes"});

                        dispatch("setSnackbar", {
                            color: "red",
                            text: "Ieraksts dzēsts",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("setCatalogItemErrorMessages", error.response.data.errors);
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        getCreditNotePdf({ commit, rootState, dispatch, state }, creditNote) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/credit_note_pdf/" + creditNote.id,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/pdf'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        let customerName = creditNote.customer.name
                        link.download =  customerName + '_' + creditNote.uuid + '.pdf'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getCreditNoteXML({ commit, rootState, dispatch, state }, payload) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/credit_note_xml/" + payload.id ,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'credit_note_' +  payload.id + '.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

    },

    getters: {
        creditNotes(state) {
            return state.creditNotes
        },
        creditNote(state) {
            return state.creditNote
        },
    }
}

export default creditNotes
