import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const users = {
  state: {
    users: null,
    userInputData: null,
    selectedUserForEdit: null,
    userErrorMessages: {
      email: null,
      password: null,
    },
    showEditRole: false,
    showEditPermissions: false,
  },

  mutations: {
    setUsers(state, users) {
      state.users = users;
      state.selectedUserForEdit = null;
    },
    selectedUserForEdit(state, user) {
      state.selectedUserForEdit = user;
    },
    setInputData(state, payload) {
      state.userInputData = payload;
    },
    setUserErrorMessages(state, error) {
      state.userErrorMessages = error;
    },
    clearUserErrorMessages(state) {
      state.userErrorMessages = {
        email: null,
        password: null,
      };
    },
    showEditRole(state) {
      state.showEditRole = true;
    },
    showEditPermissions(state) {
      state.showEditPermissions = true;
    },
    hideEditRolesAndPermissions(state) {
      state.showEditRole = false;
      state.showEditPermissions = false;
    },
  },

  actions: {
    getUsers({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/users?page=" + page + "&q=" + rootState.search)
          .then((response) => {
            commit("setUsers", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },
    getUserInputData({ commit, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/users/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    createNewUser({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/users", payload)
          .then((response) => {
            dispatch("getUsers");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            commit("clearUserErrorMessages");
            dispatch("setSnackbar", {
              color: "green",
              text: "Lietotājs izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setUserErrorMessages", error.response.data.errors);
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateUser({ commit, rootState, dispatch, state }) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/users/" + state.selectedUserForEdit.id, {
            name: state.selectedUserForEdit.name,
            email: state.selectedUserForEdit.email,
            role_id: state.selectedUserForEdit.role.id,
          })
          .then((response) => {
            dispatch("getUser");
            vm.config.globalProperties.$router.push({ path: "/users" });
            vm.config.globalProperties.$Progress.finish();
            commit("clearUserErrorMessages");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Lietotājs izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setUserErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Lietotāju neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteUser({ commit, rootState, dispatch, state }, userId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/users/" + userId)
          .then((response) => {
            dispatch("getUsers");
            dispatch("setSnackbar", {
              color: "red",
              text: "Lietotājs dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    sendUserEmail({ commit, rootState, dispatch, state }, userId) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
            .get(baseUrl + "/api/user_password_email/" + userId)
            .then((response) => {
              commit("setDataLoading", false)
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "green",
                text: "E-pasts izsūtīts",
              });
              resolve(response);
            })
            .catch((error) => {

              dispatch("setSnackbar", {
                color: "red",
                text: "E-pastu neizdevās izsūtīt",
              });
              vm.config.globalProperties.$Progress.finish();
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }
              commit("setDataLoading", false)
              reject(error);
            });
      });
    },

    getUserForEdit({ commit, rootState, dispatch, state }, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/users/edit/" + userId)
          .then((response) => {
            commit("selectedUserForEdit", response.data.user);
            commit("setInputData", response.data.inputData);
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateUserRole({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + state.selectedUserForEdit.id + "/roles",
            payload
          )
          .then((response) => {
            dispatch("getUserForEdit", state.selectedUserForEdit.id);
            commit("hideEditRolesAndPermissions");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Lietotāja loma izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setUserErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Lietotāja lomu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateUserPermissions({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + state.selectedUserForEdit.id + "/permissions",
            payload
          )
          .then((response) => {
            dispatch("getUserForEdit", state.selectedUserForEdit.id);
            commit("hideEditRolesAndPermissions");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Lietotāja atļaujas izlabotas",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setUserErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Lietotāja atļaujas neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    registerCatClick() {
      return new Promise((resolve, reject) => {
        axios.get(baseUrl + "/api/cat_clicks/users")
      });
    },

    clearUserErrorMessages({ commit }) {
      commit("clearUserErrorMessages");
    },
    showEditRole({ commit }) {
      commit("showEditRole");
    },
    hideEditRolesAndPermissions({ commit }) {
      commit("hideEditRolesAndPermissions");
    },
    showEditPermissions({ commit }) {
      commit("showEditPermissions");
    },
  },
  getters: {
    users(state) {
      return state.users;
    },
    userInputData(state) {
      return state.userInputData;
    },
    selectedUserForEdit(state) {
      return state.selectedUserForEdit;
    },
    userErrorMessages(state) {
      return state.userErrorMessages;
    },
    showEditRole(state) {
      return state.showEditRole;
    },
    showEditPermissions(state) {
      return state.showEditPermissions;
    },
  },
};

export default users;
