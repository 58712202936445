import { defineAsyncComponent } from 'vue'

const Content = defineAsyncComponent(() =>
  import('@/components/Base/Content'))
const Button = defineAsyncComponent(() =>
  import('@/components/Components/Button'))
import { createApp } from 'vue'
import vueDebounce from 'vue-debounce'
import App from './App.vue'
import './assets/index.css'
import router from './router'
import VueProgressBar from "@aacassandra/vue3-progressbar"
import store from './store'
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";
import { ObserveVisibility } from 'vue-observe-visibility';

const progressBarOptions = {
  color: '#0540a6',
  failedColor: 'red',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

export const vm = createApp(App)
.use(VueProgressBar, progressBarOptions)
.use(router)
.use(vueDebounce)
.use(store)
.use(SnackbarService)
.component("vue3-snackbar", Vue3Snackbar)

vm.component('Content', Content)
vm.component('Button', Button)
vm.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
})
vm.mount('#app');

vm.mixin({
  methods: {
    $userCan: function (permission) {
     return this.$store.getters.userCan(permission);
    },
  },
})


