import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const catalogItemParams = {
  state: {
    catalogItemParamInputData: null,
  },

  mutations: {
    setInputData(state, payload) {
      state.catalogItemParamInputData = payload;
    },
    clearInputData(state) {
      state.catalogItemParamInputData = null;
    },
  },

  actions: {
    getCatalogItemParamInputData(
      { commit, rootState, dispatch },
      catalogParamId
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/catalog_params/" + catalogParamId)
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewCatalogItemOptions(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/catalog_item_options/" + payload.catalogItemId,
            payload.data
          )
          .then((response) => {
            dispatch("clearSingleCatalogItem");
            dispatch("clearEditableItem");
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleCatalogItem", payload.catalogItemId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateCatalogItemParamValue(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/catalog_item_param_values/" +
              payload.catalogItemParamValueId,
            payload.data
          )
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch("getSingleCatalogItem", vm.$route.params.catalogItemId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteCatalogItemParamValue(
      { commit, rootState, dispatch, state },
      payload
    ) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/catalog_item_param_values/" + payload.id)
          .then((response) => {
            dispatch("getSingleCatalogItem", vm.$route.params.catalogItemId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }
            commit("clearLoading");
            reject(error);
          });
      });
    },

    clearCatalogItemOptionInputData({ commit }) {
      commit("clearInputData");
    },
  },
  getters: {
    catalogItemParamInputData(state) {
      return state.catalogItemParamInputData;
    },
  },
};

export default catalogItemParams;
