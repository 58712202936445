import {vm} from "@/main";
import orderItems from "@/store/Orders/OrderItems/orderItems";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const orders = {
    modules: {
        orderItems,
    },
    state: {
        orders: null,
        order: null,
        orderTypes: null,
    },

    mutations: {
        setOrders(state, orders) {
            state.orders = orders;
        },
        setSingleOrder(state, order) {
            state.order = order;
        },
        setOrderTypes(state, value) {
            state.orderTypes = value;
        },
    },

    actions: {
        getOrders({commit, rootState, dispatch, state}, page) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/orders?page=" + page + "&q=" + rootState.search)
                    .then((response) => {
                        commit("setOrders", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        ""
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        filterOrders({commit, rootState, dispatch, state}, queryParams) {
            dispatch("setLoading");
            commit("setDataLoading", true)

            return new Promise((resolve, reject) => {
                const rootSearch = {q: rootState.search};
                const mergedQueryParams = Object.assign(rootSearch, queryParams);

                axios
                    .get(baseUrl + "/api/orders", {
                        params: mergedQueryParams,
                    })
                    .then((response) => {
                        commit("setOrders", response.data);
                        dispatch("clearLoading");
                        commit("setDataLoading", false)
                        resolve(response);
                    })
                    .catch((error) => {
                        ""
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        dispatch("clearLoading");
                        commit("setDataLoading", false)
                        reject(error);
                    });
            });
        },

        getSingleOrder({commit, rootState, dispatch, state}, orderId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/orders/" + orderId)
                    .then((response) => {
                        commit("setSingleOrder", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        ""
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        createNewOrder({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/orders", payload.data)
                    .then((response) => {
                        vm.config.globalProperties.$router.push({name: "orders"});
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Ieraksts izveidots",
                        });
                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("clearLoading");
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        updateOrder({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + "/api/orders/" + payload.id, payload.data)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleOrder", payload.id);

                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        updateOrderDetails({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + "/api/order_details/" + payload.id, payload.data)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleOrder", payload.id);

                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        updateOrderShippingDetails({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/order_shipping/" + payload.id, payload.data)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleOrder", payload.id);

                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        mergeOrder({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/orders/" + payload.id + "/merge", payload.data)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleOrder", payload.id);

                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        addOrderItems({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/" + payload.orderId + "/order_items", {
                        order_items: payload.orderItems,
                    })
                    .then((response) => {
                        dispatch("getSingleOrder", payload.orderId);
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Ieraksts izveidots",
                        });
                        dispatch("removeAllFormsForDisplay");
                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("clearLoading");
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        sendOrderToFulfillment({commit, rootState, dispatch, state}, order) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/orders/" + order.id + "/fulfillment", {
                        order_items: order.order_items,
                    })
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("getSingleOrder", order.id);
                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        revertOrderToInFulfillment({commit, rootState, dispatch, state}, order) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/orders/" + order.id + "/revert_to_fulfillment")
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("getSingleOrder", order.id);
                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        splitOrder({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/orders/" + payload.orderId + "/split", {
                        order_items: payload.orderItems,
                    })
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        replicateOrder({commit, rootState, dispatch, state}, orderId) {

            commit('setLoading')

            return new Promise((resolve, reject) => {

                axios.get(baseUrl + '/api/orders/' + orderId + '/replicate',)
                    .then(response => {
                        vm.config.globalProperties.$Progress.finish()
                        commit('clearLoading')

                        dispatch('setSnackbar', {
                            color: 'green',
                            text: 'Ieraksts izveidots'
                        })

                        vm.config.globalProperties.$router.push({
                            name: 'orders.view', params: {
                                orderId: response.data.order_id
                            }
                        })

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail()
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu izlabot neizdevās'
                            })
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            })
        },

        issueOrderInvoice({commit, rootState, dispatch, state}, orderId) {

            commit('setLoading')

            return new Promise((resolve, reject) => {

                axios.get(baseUrl + '/api/orders/' + orderId + '/invoice',)
                    .then(response => {
                        vm.config.globalProperties.$Progress.finish()
                        commit('clearLoading')

                        vm.config.globalProperties.$router.push({
                            name: "invoice",
                            params: { invoiceId: response.data.invoice_id},
                        });

                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail()
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu izlabot neizdevās'
                            })
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            })
        },

        cancelOrder({commit, rootState, dispatch, state}, orderId) {

            commit('setLoading')

            return new Promise((resolve, reject) => {

                axios.get(baseUrl + '/api/orders/' + orderId + '/cancel',)
                    .then(response => {
                        vm.config.globalProperties.$Progress.finish()
                        commit('clearLoading')

                        dispatch('setSnackbar', {
                            color: 'blue',
                            text: 'Ieraksts izlabots'
                        })

                        dispatch('getSingleOrder', orderId)

                        resolve(response)
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail()
                            dispatch('setSnackbar', {
                                color: 'red',
                                text: 'Ierakstu izlabot neizdevās'
                            })
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            })
        },


        deleteOrder({commit, rootState, dispatch, state}, orderId) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + "/api/orders/" + orderId)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        dispatch("removeAllFormsForDisplay");
                        vm.config.globalProperties.$router.push({name: "orders"});

                        dispatch("setSnackbar", {
                            color: "red",
                            text: "Ieraksts dzēsts",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        if (error.response.status === 403) {
                            dispatch("getSingleOrder", orderId);
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu aizliegts dzēst",
                            });
                            vm.config.globalProperties.$Progress.finish();
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        clearSingleOrder({commit}) {
            commit("setSingleOrder", null);
        },

        getDeliveryTypes({commit, rootState, dispatch, state}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/delivery_types")
                    .then((response) => {
                        commit("setOrderTypes", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        ""
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        getOrderProformaInvoicePdf({ commit, rootState, dispatch, state }, order) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/orders/proforma_invoice_pdf/" + order.id,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/pdf'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        let customerName = order.customer.name
                        link.download =  customerName + '_proforma_' + order.uuid + '.pdf'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getOrderItemExcel({ commit, rootState, dispatch, state }, order) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/orders/unique_codes_excel/" + order.id, {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xlsx'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        link.download = order.uuid + '_positions.xlsx'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getOrderInvoicePdf({ commit, rootState, dispatch, state }, order) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/orders/invoice_pdf/" + order.id,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/pdf'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        let customerName = order.customer.name
                        link.download =  customerName + '_' + order.invoice.invoice_uuid + '.pdf'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getOrderInvoicesReportExcel({ commit, rootState, dispatch, state }, payload) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/invoices_report_excel?from=" + payload.from + '&to=' + payload.to +'&q='+rootState.search,
                        {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xlsx'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        link.download = 'invoices_report.xlsx'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },


        getOrderInvoiceReportPdf({ commit, rootState, dispatch, state }, payload) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/invoices_report?from=" + payload.from + '&to=' + payload.to + '&q='+rootState.search,
                        {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/pdf'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        link.download =  'invoices_report.pdf'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },


        getOrderInvoiceXML({ commit, rootState, dispatch, state }, id) {

            commit('setLoading')
    
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/orders/invoice_xml/" + id ,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})
    
                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }
    
                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'documents_' +  id + '.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)
    
                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }
    
                        commit('clearLoading')
                        reject(error)
                    })
            });
        },
    },
    getters: {
        orders(state) {
            return state.orders;
        },
        order(state) {
            return state.order;
        },
        orderTypes(state) {
            return state.orderTypes;
        },
    },
};

export default orders;
