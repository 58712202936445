import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const branch = {
  state: {
    paymentDetailInputData: null,
    discountInputData: null,
    branchImageUploadError: null,
  },

  mutations: {
    setPaymentDetailInputData(state, payload) {
      state.paymentDetailInputData = payload;
    },
    setBranchImageUploadError(state, error) {
      state.branchImageUploadError = error;
    },
    clearBranchImageUploadError(state) {
      state.branchImageUploadError = null;
    },
  },

  actions: {
    createNewBranchPaymentDetails(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.branchId + "/branch_payment_details",
            payload.data
          )
          .then((response) => {
            dispatch("getSingleBranch", payload.branchId);
            commit("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setBranchErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateBranchPaymentDetails(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/branch_payment_details/" + payload.data.id,
            payload.data
          )
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              commit("setBranchErrorMessages", error.response.data.errors);
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    uploadBranchImages({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/branch_files/" + payload.id, payload.files)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleBranch", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("clearLoading");
            dispatch("setSnackbar", {
              color: "green",
              text: "Bildes saglabātas",
            });

            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit(
                "setBranchImageUploadError",
                "Failam jābūt bildei un MAX 8 MB"
              );
              dispatch("clearLoading");
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }
            reject(error);
          });
      });
    },

    deleteBranchImage({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/files/" + payload.id)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleBranch", payload.branchId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("clearEditableItem");
            dispatch("setSnackbar", {
              color: "red",
              text: "Bilde dzēsta",
            });

            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            reject(error);
          });
      });
    },

    clearBranchImageUploadError({ commit }) {
      commit("clearBranchImageUploadError");
    },
  },
  getters: {
    branchImageUploadError(state) {
      return state.branchImageUploadError;
    },
  },
};

export default branch;
