import { vm } from "@/main";
import axios from "axios";
import catalogItemParams from "./CatalogItemParams/catalogItemParams";
import catalogItemParamValues from "@/store/Settings/Catalog/CatalogItemParamValues/catalogItemParamValues";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const catalogItems = {
  modules: {
    catalogItemParams,
    catalogItemParamValues,
  },
  state: {
    catalogItem: null,
    catalogItems: null,
    catalogItemInputData: null,
    catalogItemErrorMessages: {
      sku: null,
    },
    catalogItemImageUploadError: "",
  },

  mutations: {
    setCatalogItems(state, catalogItems) {
      state.catalogItems = catalogItems;
      state.selectedUserForEdit = null;
    },
    setSingleCatalogItem(state, catalogItem) {
      state.catalogItem = catalogItem;
    },
    clearSingleCatalogItem(state) {
      state.catalogItem = null;
    },
    setInputData(state, payload) {
      state.catalogItemInputData = payload;
    },
    setCatalogItemErrorMessages(state, errors) {
      state.catalogItemErrorMessages = errors;
    },
    setCatalogItemImageUploadError(state, error) {
      state.catalogItemImageUploadError = error;
    },
  },

  actions: {
    getSingleCatalogItem(
      { commit, rootState, dispatch, state },
      catalogItemId
    ) {
      return new Promise((resolve, reject) => {
        commit("clearSingleInventoryItem");

        axios
          .get(baseUrl + "/api/catalog_items/" + catalogItemId)
          .then((response) => {
            commit("setSingleCatalogItem", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            if (error.response.status === 404) {
              commit("setInventoryErrorMessages", {
                notFound: "Inventārs ar šādu UUID neeksistē",
              });
            }
            reject(error);
          });
      });
    },

    getCatalogItems({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/catalog_items?page=" +
              page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setCatalogItems", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getCatalogItemInputData({ commit, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/catalog_items/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    createNewCatalogItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.categoryId + "/catalog_items",
            payload.data
          )
          .then((response) => {
            dispatch("getCatalogItems");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setCatalogItemErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateCatalogItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/catalog_items/" + payload.id, payload)
          .then((response) => {
            dispatch("clearSingleCatalogItem");
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleCatalogItem", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setCatalogItemErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateCatalogItemWebshopStatus({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .patch(baseUrl + "/api/catalog_item_display_in_webshop/" + payload.id, payload.data)
            .then((response) => {
              dispatch("removeAllFormsForDisplay");
              vm.config.globalProperties.$Progress.finish();
              dispatch("getSingleCatalogItem", payload.id);
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu neizdevās izlabot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },

    updateCatalogItemKnCodeWeightCountry({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .patch(baseUrl + "/api/catalog_items_kn_code_weight_country/" + payload.id, payload.data)
            .then((response) => {
              dispatch("removeAllFormsForDisplay");
              vm.config.globalProperties.$Progress.finish();
              dispatch("getSingleCatalogItem", payload.id);
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu neizdevās izlabot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },

    uploadCatalogItemImage({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/catalog_items/images/" + payload.id,
            payload.file
          )
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleCatalogItem", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("clearLoading");
            dispatch("setSnackbar", {
              color: "green",
              text: "Bilde saglabāta",
            });

            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit(
                "setCatalogItemImageUploadError",
                "Failam jābūt bildei un MAX 8 MB"
              );
              dispatch("clearLoading");
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }
            reject(error);
          });
      });
    },

    deleteCatalogItem({ commit, rootState, dispatch, state }, catalogItemId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/catalog_items/" + catalogItemId)
          .then((response) => {
            vm.config.globalProperties.$router.push("/catalog");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    uploadCatalogItemInWebshop(
      { commit, rootState, dispatch, state },
      catalogItemId
    ) {
      return new Promise((resolve, reject) => {
        commit("clearSingleInventoryItem");

        axios
          .get(baseUrl + "/api/catalog_items/webshop/" + catalogItemId)
          .then((response) => {
            dispatch("getSingleCatalogItem", catalogItemId);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            if (error.response.status === 404) {
              commit("setInventoryErrorMessages", {
                notFound: "Inventārs ar šādu UUID neeksistē",
              });
            }
            reject(error);
          });
      });
    },

    clearSingleCatalogItem({ commit }) {
      commit("clearSingleCatalogItem");
    },
    clearCatalogItemErrorMessages({ commit }) {
      commit("setCatalogItemErrorMessages", {
        sku: null,
      });
    },
    clearBranchImageUploadError({ commit }) {
      commit("setCatalogItemImageUploadError", "");
    },

    createNewCatalogItemSupplier({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/supplier_catalog_items/" + payload.catalog_item_id,
            payload.data
          )
          .then((response) => {
            dispatch("getSingleCatalogItem", payload.catalog_item_id);
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Piegādātājs pievienots",
            });

            commit('removeAllFormsForDisplay')
            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setCatalogItemErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateCatalogItemSupplier({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/supplier_catalog_items/" + payload.id, payload.data)
          .then((response) => {
            dispatch("clearSingleCatalogItem");
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleCatalogItem", payload.catalog_item_id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            commit("clearEditableItem");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setCatalogItemErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteCatalogItemSupplier({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/supplier_catalog_items/" + payload.id)
          .then((response) => {

            dispatch("getSingleCatalogItem", payload.catalog_item_id);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    catalogItem(state) {
      return state.catalogItem;
    },
    catalogItems(state) {
      return state.catalogItems;
    },
    catalogItemInputData(state) {
      return state.catalogItemInputData;
    },
    catalogItemErrorMessages(state) {
      return state.catalogItemErrorMessages;
    },
    catalogItemImageUploadError(state) {
      return state.catalogItemImageUploadError;
    },
  },
};

export default catalogItems;
