import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const customerOrders = {
  state: {
    customerOrders: null,
  },

  mutations: {
    setCustomerOrders(state, payload) {
      state.customerOrders = payload;
    },
  },

  actions: {
    getCustomerOrders({ commit, rootState, dispatch, state }, customerId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/" + customerId + "/customer_order_items")
          .then((response) => {
            commit("setCustomerOrders", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    customerOrders(state) {
      return state.customerOrders;
    },
  },
};

export default customerOrders;
