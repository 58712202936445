import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const stockBalance = {
  state: {
    warehousesList: null,
  },

  mutations: {
    setWarehousesList(state, warehousesList) {
      state.warehousesList = warehousesList;
    },
  },

  actions: {
    getWarehousesList({ commit, rootState, dispatch, state }, productId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock_items/technical_documents/create")
          .then((response) => {
            commit("setWarehousesList", response.data.warehouses);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    storeStockBalance({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/stock_items/technical_documents/stock_item_corrections",
            payload
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            vm.config.globalProperties.$router.push({
              name: "tehnical_documents",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    storeStockMovement({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/stock_items/technical_documents/goods_removals",
            payload
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            vm.config.globalProperties.$router.push({
              name: "tehnical_documents",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
  },
  getters: {
    warehousesList(state) {
      return state.warehousesList;
    },
  },
};

export default stockBalance;
