import axios from "axios";
import { vm } from "@/main";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const assignUserToContact = {
  state: {
    contact: null,
  },

  mutations: {
    setContact(state, contact) {
      state.contact = contact;
    },
    clearContact(state) {
      state.contact = null;
    },
  },

  actions: {
    getContact({ commit, rootState, dispatch, state }, token) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/assign_user_to_contact/create/" + token)
          .then((response) => {
            commit("setContact", response.data);
            resolve(response);
          })
          .catch((error) => {
            dispatch("clearContact");
            if (error.response.status === 403) {
              vm.config.globalProperties.$router.push({ name: "Login" });
            }
            reject(error);
          });
      });
    },

    inviteUserToContact({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/assign_user_to_contact/" + payload.contactId)
          .then((response) => {
            if (payload.customerId) {
              dispatch("getCustomer", payload.customerId);
            } else {
              dispatch("getSingleBranch", payload.branchId);
            }

            dispatch("setSnackbar", {
              color: "green",
              text: "Uzaicinājums izsūtīts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    revokeUserFromContact({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/assign_user_to_contact/" + payload.contactId)
          .then((response) => {
            if (payload.customerId) {
              dispatch("getCustomer", payload.customerId);
            } else {
              dispatch("getSingleBranch", payload.branchId);
            }
            dispatch("setSnackbar", {
              color: "green",
              text: "Lietotāja tiesības atceltas",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    acceptAssignUserToContact({ commit, rootState, dispatch, state }, contact) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/assign_user_to_contact/" +
              contact.id +
              "/" +
              contact.assign_user_token
          )
          .then((response) => {
            vm.config.globalProperties.$router.push({ name: "Home" });
            dispatch("setSnackbar", {
              color: "green",
              text: "Uzaicinājums apstiprināts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    clearContact({ commit }) {
      commit("clearContact");
    },
  },
  getters: {
    contact(state) {
      return state.contact;
    },
  },
};

export default assignUserToContact;
