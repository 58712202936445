import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL

const invoicesExport = {

    state: {
        dailyInvoicesReport: null,
        updatedInvoices: null,
    },

    mutations: {
        setDailyInvoicesReports(state, dailyInvoicesReport){
            state.dailyInvoicesReport = dailyInvoicesReport
        },
        setUpdatedInvoices(state, updatedInvoices){
            state.updatedInvoices = updatedInvoices
        },
        clearUpdatedInvoices(state){
            state.updatedInvoices = null
        },
    },

    actions: {
        getDailyInvoicesReports({ commit, rootState, dispatch, state }, dates = {
            from: '',
            to: '',
        }) {
            commit("setDataLoading", true)

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/daily_invoices_reports?from=' + dates.from + '&to='+dates.to)
                    .then(response => {
                        commit('setDailyInvoicesReports', response.data)
                        commit("setDataLoading", false)
                        resolve(response)
                    })
                    .catch(error => {
                         
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        commit("setDataLoading", false)
                        reject(error)
                    })
            })
        },

        getSingleDayUpdatedInvoices({ commit, rootState, dispatch, state }, date) {

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/daily_updated_invoices/' + date)
                    .then(response => {
                        commit('setUpdatedInvoices', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                         
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        getDailyInvoicesXML({ commit, rootState, dispatch, state }, date) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/daily_invoices_reports/" + date ,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'documents_' +  date + '.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        dispatch('getDailyInvoicesReports', {
                            from: state.dailyInvoicesReport.from,
                            to: state.dailyInvoicesReport.to,
                        })

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getDailyCatalogItemsXML({ commit, rootState, dispatch, state }, date) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/daily_catalog_items_reports/" + date ,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'products_' + date + '.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        dispatch('getDailyInvoicesReports', {
                            from: state.dailyInvoicesReport.from,
                            to: state.dailyInvoicesReport.to,
                        })

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getInvoicesXmlReport({ commit, rootState, dispatch, state }, data) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/invoices_xml_report?from=" + data.from + '&to=' +data.to , {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'invoices_report.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        dispatch('getDailyInvoicesReports', {
                            from: state.dailyInvoicesReport.from,
                            to: state.dailyInvoicesReport.to,
                        })

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getDailyUpdatedXML({ commit, rootState, dispatch, state }, date) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/daily_updated_invoices_xml/" + date ,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'updated_documents_' +  date + '.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        dispatch('getDailyInvoicesReports', {
                            from: state.dailyInvoicesReport.from,
                            to: state.dailyInvoicesReport.to,
                        })

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getInvoicesExcelReport({ commit, rootState, dispatch, state }, data) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/invoices_excel_report?from=" + data.from + '&to=' +data.to , {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xlsx'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'invoices.xlsx'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        dispatch('getDailyInvoicesReports', {
                            from: state.dailyInvoicesReport.from,
                            to: state.dailyInvoicesReport.to,
                        })

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        clearUpdatedInvoices ({commit}) {
            commit('clearUpdatedInvoices')
        }
    },

    getters: {
        dailyInvoicesReport(state){
            return state.dailyInvoicesReport
        },
        updatedInvoices(state){
            return state.updatedInvoices
        },
    }
}

export default invoicesExport
