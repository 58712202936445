import invoicesExport from "@/store/Invoices/invoicesExport";
import axios from "axios";
import invoice from "./invoice";
import reports from "@/store/Invoices/reports";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL

const invoices = {

    modules: {
        invoice,
        invoicesExport,
        reports,
    },

    state: {
        invoices: null,
        invoice: null,
    },

    mutations: {
        setInvoices(state, invoices){
            state.invoices = invoices
        },
        setInvoice(state, invoice){
            state.invoice = invoice
        },
    },

    actions: {
        getInvoices({ commit, rootState, dispatch, state }, queryParams){
            commit("setDataLoading", true)
            return new Promise((resolve, reject) => {

                const rootSearch = {q: rootState.search};
                const mergedQueryParams = Object.assign(rootSearch, queryParams);

                axios.get(baseUrl +'/api/invoices', {
                    params: mergedQueryParams,
                })
                    .then(response => {
                        commit('setInvoices', response.data)
                        commit("setDataLoading", false)
                        resolve(response)
                    })
                    .catch(error => {
                         
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        commit("setDataLoading", false)
                        reject(error)
                    })
            })
        },

        getSingleInvoice({ commit, rootState, dispatch, state }, id){
            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/invoices/'+id)
                    .then(response => {
                        commit('setInvoice', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                         
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        reject(error)
                    })
            })
        },

        getInvoicePdf({ commit, rootState, dispatch, state }, invoice) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/invoices/pdf/" + invoice.id,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/pdf'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        let customerName = invoice.customer.name ? invoice.customer.name : invoice.customer
                        link.download =  customerName + '_' + invoice.uuid + '.pdf'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getInvoiceXml({ commit, rootState, dispatch, state }, invoice) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/invoices/xml/" + invoice.id ,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = invoice.uuid + '.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getInvoiceBranchReportPdf({ commit, rootState, dispatch, state }, invoice) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/invoices/branch_report_pdf/" + invoice.id,  {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/pdf'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download =   invoice.customer.name + '_Filiales_' + invoice.uuid + '.pdf'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        clearInvoices({commit}){
            commit('setInvoices', null)
        }

    },

    getters: {
        invoices(state){
            return state.invoices
        },
        invoice(state){
            return state.invoice
        },
    }
}

export default invoices
