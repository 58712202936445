import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const stockChecks = {
  state: {
    stockChecks: null,
    stockCheck: null,
    stockCheckInputData: null,
  },

  mutations: {
    setStockChecks(state, stockChecks) {
      state.stockChecks = stockChecks;
    },
    setStockCheck(state, stockCheck) {
      state.stockCheck = stockCheck;
    },
    setStockCheckInputData(state, stockCheckInputData) {
      state.stockCheckInputData = stockCheckInputData;
    },
  },

  actions: {
    getStockChecks({ commit, rootState, dispatch, state }, params) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/stock_checks?" +
              "page=" +
              params.page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setStockChecks", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getStockCheck({ commit, rootState, dispatch, state }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/stock_checks/" + id
          )
          .then((response) => {
            commit("setStockCheck", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    getStockChecksInputData({ commit, rootState, dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/stock_checks/create"
          )
          .then((response) => {
            commit("setStockCheckInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    getStockCheckXML({ commit, rootState, dispatch, state }, id) {

        commit('setLoading')

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
        return new Promise((resolve, reject) => {
            axios
                .get(baseUrl + "/api/stock/stock_check_xml/" + id ,  {responseType: 'arraybuffer'})
                .then((response) => {
                    // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    var newBlob = new Blob([response.data], {type: 'application/xml'})

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob)
                        return
                    }

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(newBlob)
                    var link = document.createElement('a')
                    link.href = data
                    link.download = 'documents_' +  id + '.xml'
                    link.click()
                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data)
                    }, 100)

                    commit('clearLoading')
                    resolve(response);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        dispatch('destroyToken')
                        location.reload();
                    }

                    commit('clearLoading')
                    reject(error)
                })
        });
    },

    acceptStockCheck({ commit, rootState, dispatch, state }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/accept_stock_check/" + id
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch('getStockCheck', id)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewStockCheck({ commit, rootState, dispatch, state }, payload) {
        commit("setLoading");
  
        return new Promise((resolve, reject) => {
          axios
            .post(baseUrl + "/api/stock/stock_checks", payload)
            .then((response) => {
              dispatch("getStockChecks");
              dispatch("removeAllFormsForDisplay");
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "green",
                text: "Ieraksts izveidots",
              });
  
              commit("clearLoading");
              resolve(response);
              vm.config.globalProperties.$router.push("/stock/stock_checks");
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu izveidot neizdevās",
                });
              }
  
              commit("clearLoading");
              reject(error);
            });
        });
    },

    deleteStockCheck({ commit, rootState, dispatch, state }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/stock/stock_checks/" + id)
          .then((response) => {
            vm.config.globalProperties.$router.push('/stock/stock_checks');
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    stockChecks(state) {
      return state.stockChecks;
    },
    stockCheck(state) {
      return state.stockCheck;
    },
    stockCheckInputData(state) {
      return state.stockCheckInputData;
    },
  },
};

export default stockChecks;
