import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const invoice = {
  state: {
    singleInvoice: null,
  },

  mutations: {
    setInvoice(state, invoice) {
      state.singleInvoice = invoice;
    },
    clearSingleInvoice(state) {
      state.singleInvoice = null;
    },
  },

  actions: {
    getSingleInvoice({ commit, rootState, dispatch, state }, invoiceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/invoices/" + invoiceId)
          .then((response) => {
            commit("setInvoice", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateInvoice({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/invoices/" + payload.id, payload)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleInvoice", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateInvoiceItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/invoice_items/" + payload.itemId, payload.data)
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch("getSingleInvoice", payload.invoiceId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteInvoice({ commit, rootState, dispatch, state }, itemId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/invoices/" + itemId)
          .then((response) => {
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    deleteInvoiceItem({ commit, rootState, dispatch, state }, item) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/invoice_items/" + item.id)
          .then((response) => {
            dispatch("getSingleInvoice", item.invoice_id);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    sendInvoiceByEmail({ commit, rootState, dispatch, state }, invoiceId) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/invoices/email/" + invoiceId)
          .then((response) => {
            dispatch("getSingleInvoice", invoiceId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    sendInvoiceByEmailToAddress({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/invoices/email_to_address/" + payload.invoiceId,
            {
                address: payload.address,
                document_type: payload.document_type
            }
          )
          .then((response) => {
            // dispatch("getSingleInvoice", payload.invoiceId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "E-pasts izsūtīts",
            });
            dispatch("removeAllFormsForDisplay")
            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            commit("clearLoading");

            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "E-pastu nosūtīt neizdevās",
              });
            }

            reject(error);
          });
      });
    },

    clearSingleInvoice({ commit }) {
      commit("clearSingleInvoice");
    },

    uploadInvoiceImages({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/invoice_images/" + payload.id, payload.files)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleInvoice", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("clearLoading");
            dispatch("setSnackbar", {
              color: "green",
              text: "Bildes saglabātas",
            });

            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit(
                "setBranchImageUploadError",
                "Failam jābūt bildei un MAX 8 MB"
              );
              dispatch("clearLoading");
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }
            reject(error);
          });
      });
    },

    deleteInvoiceImage({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/files/" + payload.id)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleInvoice", payload.invoiceId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("clearEditableItem");
            dispatch("setSnackbar", {
              color: "red",
              text: "Bilde dzēsta",
            });

            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            reject(error);
          });
      });
    },
  },

  getters: {
    singleInvoice(state) {
      return state.singleInvoice;
    },
  },
};

export default invoice;
