import axios from "axios";
import { vm } from "@/main";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const auth = {
  state: {
    token: localStorage.getItem("access_token") || null,
    user: null,
    authErrorStatus: false,
    authErrorMessage: null,
    createPasswordForm: false,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    destroyToken(state) {
      state.token = null;
      state.user = null;
    },
    setAuthErrorMessage(state, message) {
      state.authErrorMessage = message;
      state.authErrorStatus = true;
    },
    resetErrors(state) {
      state.authErrorStatus = false;
      state.authErrorMessage = null;
    },
    showCreatePasswordForm(state) {
      state.createPasswordForm = true;
    },
    hideCreatePasswordForm(state) {
      state.createPasswordForm = false;
    },
  },
  actions: {
    getToken({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/login", {
            email: credentials.email,
            password: credentials.password,
          })
          .then((response) => {
            const token = response.data.access_token;

            localStorage.setItem("access_token", token);
            commit("resetErrors");
            commit("setToken", token);
            dispatch("getUser");
            dispatch("getSystemMainCustomer");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 403) {
              // console.log(error.response.data.message);
              commit("setAuthErrorMessage", error.response.data.message);
            }
            reject(error);
          });
      });
    },
    destroyToken({ state, getters, commit }) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;

      if (getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get(baseUrl + "/api/logout")
            .then((response) => {
              localStorage.removeItem("access_token");
              commit("destroyToken");
              vm.config.globalProperties.$router.push("/login");
              resolve(response);
            })
            .catch((error) => {
              localStorage.removeItem("access_token");
              commit("destroyToken");
              vm.config.globalProperties.$router.push("/login");
              reject(error);
            });
        });
      }
    },
    getUser({ commit, state, dispatch }) {
      if (state.token == null) {
        return;
      }

      axios.defaults.headers.common["Authorization"] = "Bearer " + state.token;

      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/user")
          .then((response) => {
            commit("setUser", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            if (error.response.status === 403) {
              vm.config.globalProperties.$router.push({ name: "Login" });
              dispatch("setSnackbar", {
                color: "red",
                text: "Lietotājs nav atrasts",
              });
            }
            reject(error);
          });
      });
    },

    checkIfPasswordFormIsAllowed(
      { commit, rootState, dispatch, state },
      token
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/user_password/create/" + token)
          .then((response) => {
            dispatch("showCreatePasswordForm");
            resolve(response);
          })
          .catch((error) => {
            dispatch("hideCreatePasswordForm");
            if (error.response.status === 403) {
              vm.config.globalProperties.$router.push({ name: "Login" });
            }
            reject(error);
          });
      });
    },

    createUserPassword({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/store_user_password/" + payload.token, {
            password: payload.password,
          })
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            vm.config.globalProperties.$router.push({ name: "Login" });
            commit("clearUserErrorMessages");
            commit("hideCreatePasswordForm");
            dispatch("setSnackbar", {
              color: "green",
              text: "Parole izveidota",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              vm.config.globalProperties.$router.push({ name: "Login" });
              dispatch("setSnackbar", {
                color: "red",
                text: "Parole nav izveidota",
              });
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setUserErrorMessages", error.response.data.errors);
            }
            reject(error);
          });
      });
    },
    showCreatePasswordForm({ commit }) {
      commit("showCreatePasswordForm");
    },
    hideCreatePasswordForm({ commit }) {
      commit("hideCreatePasswordForm");
    },
  },

  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    user(state) {
      return state.user;
    },
    userFunction(state) {
      return function() {
        return state.user;
      };
    },
    authErrorStatus(state) {
      return state.authErrorStatus;
    },
    authErrorMessage(state) {
      return state.authErrorMessage;
    },
    createPasswordForm(state) {
      return state.createPasswordForm;
    },
    userCan: (state) => (permission) => {
      if (!state.user) {
        return false;
      }

      return state.user.permissions.includes(permission);
    },
  },
};

export default auth;
