import axios from "axios";
import {vm} from '@/main'

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL

const reports = {

    state: {
        reports: null
    },

    mutations: {
        setReports(state, reports){
            state.reports = reports
        },
    },

    actions: {
        getReports({ commit, rootState, dispatch, state }, params){
            commit("setDataLoading", true)

            return new Promise((resolve, reject) => {

                axios.get(baseUrl +'/api/reports/invoices?from=' + params.from + '&to=' + params.to + '&status=' + (params.status ? params.status : '') + '&type=' + (params.type ? params.type : ''))
                    .then(response => {
                        commit('setReports', response.data)
                        commit("setDataLoading", false)
                        resolve(response)
                    })
                    .catch(error => {
                         
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                        }
                        commit("setDataLoading", false)
                        reject(error)
                    })
            })
        },
    },

    getters: {
        reports(state){
            return state.reports
        },
    }
}

export default reports
