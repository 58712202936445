import axios from "axios";
import {vm} from "@/main";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const stockReports = {
    state: {},

    mutations: {},

    actions: {
        getStockReportExcel({commit, rootState, dispatch, state}, payload) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl +
                        "/api/reports/show?from=" + payload.from +
                        '&to=' + payload.to +
                        '&type=' + payload.type +
                        '&showUniqueUnits=' + payload.showUniqueUnits +
                        '&customer_id=' + payload.customer_id +
                        '&format=xlsx',
                        {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xlsx'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        link.download = payload.type + '_report.xlsx'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Neizdevās izveido atskaiti",
                            });
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },
    },

    getters: {},
};

export default stockReports;
