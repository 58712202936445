import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const categories = {
  state: {
    categories: null,
    fetchedCategories: null,
    selectedCategory: {},
    categoryForEdit: null,
    categoryInputData: null,
    categorySubcategories: null,
    categoryProducts: null,
    categoryPriceRates: null,
  },

  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setFetchedCategories(state, fetchedCategories) {
      state.fetchedCategories = fetchedCategories;
    },
    setInputData(state, payload) {
      state.categoryInputData = payload;
    },
    setSelectedCategory(state, category) {
      state.selectedCategory = category;
    },
    clearSelectedCategory(state) {
      state.selectedCategory = {};
    },
    setCategoryForEdit(state, category) {
      state.categoryForEdit = category;
    },
    clearCategoryForEdit(state) {
      state.categoryForEdit = null;
    },
    setCategorySubcategories(state, subCategories) {
      state.categorySubcategories = subCategories
    },
    setCategoryProducts(state, categoryProducts) {
      state.categoryProducts = categoryProducts;
    },
    setCategoryPriceRates(state, categoryPriceRates) {
      state.categoryPriceRates = categoryPriceRates;
    },
  },

  actions: {
    getCategories({ commit, rootState, dispatch, state }, queryParams) {
      commit("setDataLoading", true)
      const rootSearch = { q: rootState.search };
      const mergedQueryParams = Object.assign(rootSearch, queryParams);

      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/categories", {
            params: mergedQueryParams,
          })
          .then((response) => {
            commit("setCategories", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    fetchCategories({ commit, rootState, dispatch, state }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/fetch_categories", {
            params: queryParams,
          })
          .then((response) => {
            commit("setFetchedCategories", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    getSingleCategory({ commit, rootState, dispatch, state }, categoryId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/categories/" + categoryId)
          .then((response) => {
            commit("setSelectedCategory", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    getDefaultCategoryPriceRates({ commit, rootState, dispatch, state }, categoryId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/category_price_rate/create/")
          .then((response) => {
            commit("setCategoryPriceRates", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewCategory({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/categories", payload)
          .then((response) => {
            dispatch("getCategories");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateCategory({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/categories/" + payload.id, payload)
          .then((response) => {
            dispatch("clearCategoryForEdit");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateCategoryPriceRate({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/category_price_rate/" + payload.id, payload.data)
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();

            dispatch("getSingleCategory", payload.id)

            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateCategoryWebshopStatus({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/category_display_in_webshop/" + payload.id, payload.data)
          .then((response) => {
            dispatch("clearCategoryForEdit");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateCategoryOptions({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .patch(baseUrl + "/api/categories/" + payload.id, payload.data)
            .then((response) => {
              dispatch("clearCategoryForEdit");
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu neizdevās izlabot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },
    updateCategoryKnCodeWeightCountry({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .patch(baseUrl + "/api/category_kn_code_weight_country/" + payload.id, payload.data)
            .then((response) => {
              dispatch("clearCategoryForEdit");
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu neizdevās izlabot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },

    deleteCategory({ commit, rootState, dispatch, state }, categoryId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/categories/" + categoryId)
          .then((response) => {
            dispatch("getCategories");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    setSelectedCategory({ commit }, category) {
      commit("setSelectedCategory", category);
    },
    clearSelectedCategory({ commit }) {
      commit("clearSelectedCategory");
    },
    setCategoryForEdit({ commit }, category) {
      commit("setCategoryForEdit", category);
    },
    clearCategoryForEdit({ commit }) {
      commit("clearCategoryForEdit");
    },
    getSubcategories({ commit, rootState, dispatch, state }, categoryId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/subcategories/" + categoryId)
          .then((response) => {
            commit("setCategorySubcategories", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    getCategoryProducts({ commit, rootState, dispatch, state }, {queryParams, id, loading = true}) {
      commit("setDataLoading", loading)
      return new Promise((resolve, reject) => {
        const rootSearch = { q: rootState.search };
        const mergedQueryParams = Object.assign(rootSearch, queryParams);

        axios
          .get(baseUrl + "/api/category_products/" + id, {
              params: mergedQueryParams,
          })
          .then((response) => {
            commit("setCategoryProducts", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },
  },
  getters: {
    categories(state) {
      return state.categories;
    },
    fetchedCategories(state) {
      return state.fetchedCategories;
    },
    selectedCategory(state) {
      return state.selectedCategory;
    },
    categoryForEdit(state) {
      return state.categoryForEdit;
    },
    categoryInputData(state) {
      return state.categoryInputData;
    },
    categorySubcategories(state) {
      return state.categorySubcategories;
    },
    categoryProducts(state) {
      return state.categoryProducts;
    },
    categoryPriceRates(state) {
      return state.categoryPriceRates;
    },
  },
};

export default categories;
