import {vm} from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const procurementReturnActs = {
    state: {
        procurementReturnAct: null,
        procurementsReturnActs: null,
    },

    mutations: {
        setProcurementReturnActs(state, procurementsReturnActs) {
            state.procurementsReturnActs = procurementsReturnActs;
        },
        setSingleProcurementReturnAct(state, procurementReturnAct) {
            state.procurementReturnAct = procurementReturnAct;
        },
    },

    actions: {
        getProcurementReturnActs({commit, rootState, dispatch, state}, queryParams) {
            dispatch("setLoading");

            return new Promise((resolve, reject) => {
                commit("setDataLoading", true)
                const rootSearch = {q: rootState.search};
                const mergedQueryParams = Object.assign(rootSearch, queryParams);

                axios
                    .get(baseUrl + "/api/stock/procurement_return_acts", {
                        params: mergedQueryParams,
                    })
                    .then((response) => {
                        commit("setProcurementReturnActs", response.data);
                        dispatch("clearLoading");
                        commit("setDataLoading", false)
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        dispatch("clearLoading");
                        commit("setDataLoading", false)
                        reject(error);
                    });
            });
        },

        getSingleProcurementReturnAct(
            {commit, rootState, dispatch, state},
            id
        ) {
            commit("setDataLoading", true)
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/stock/procurement_return_acts/" + id)
                    .then((response) => {
                        commit("setSingleProcurementReturnAct", response.data);
                        commit("setDataLoading", false)
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        commit("setDataLoading", false)
                        reject(error);
                    });
            });
        },

        getProcurementReturnActPdf({commit, rootState, dispatch, state}, returnAct) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/stock/procurement_return_acts_pdf/" + returnAct.id, {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/pdf'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        link.download = returnAct.id + '_' + returnAct.uuid + '.pdf'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getProcurementReturnActXML({commit, rootState, dispatch, state}, id) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/stock/procurement_return_acts_xml/" + id, {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xml'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data
                        link.download = 'documents_' + id + '.xml'
                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        getProcurementReturnActExcel({commit, rootState, dispatch, state}, returnAct) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/stock/procurement_return_acts_excel/" + returnAct.id, {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xlsx'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        link.download = returnAct.uuid + '_positions.xlsx'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },

        updateProcurementReturnAct({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .patch(baseUrl + "/api/stock/procurement_return_acts/" + payload.id, payload)
                    .then((response) => {
                        dispatch("clearEditableItem");
                        dispatch("getSingleProcurementReturnAct", payload.id);
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu neizdevās izlabot",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        deleteProcurementReturn({commit, rootState, dispatch, state}, returnActId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + "/api/stock/procurement_return_acts/" + returnActId)
                    .then((response) => {
                        dispatch("getProcurements", 1);
                        dispatch("setSnackbar", {
                            color: "red",
                            text: "Ieraksts dzēsts",
                        });
                        vm.config.globalProperties.$Progress.finish();

                        vm.config.globalProperties.$router.push({
                            path: "/stock/procurement_return_acts",
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Šo ieprikumu nedrīkst dzēst",
                            });
                            vm.config.globalProperties.$Progress.fail()
                        }
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
    },
    getters: {
        procurementReturnAct(state) {
            return state.procurementReturnAct;
        },
        procurementsReturnActs(state) {
            return state.procurementsReturnActs;
        },
    },
};

export default procurementReturnActs;
