import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const companyForms = {
  state: {
    companyForms: null,
  },

  mutations: {
    setCompanyForms(state, companyForms) {
      state.companyForms = companyForms;
    },
  },

  actions: {
    getCompanyForms({ commit, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/company_settings/company_forms?page=" + page)
          .then((response) => {
            commit("setCompanyForms", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    createNewCompanyForm({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/company_settings/company_forms", payload)
          .then((response) => {
            dispatch("getCompanyForms");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateCompanyForm({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/company_settings/company_forms/" + payload.id,
            payload
          )
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch("getCompanyForms");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteCompanyForm({ commit, rootState, dispatch, state }, companyFormId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            baseUrl + "/api/company_settings/company_forms/" + companyFormId
          )
          .then((response) => {
            dispatch("getCompanyForms");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    companyForms(state) {
      return state.companyForms;
    },
  },
};

export default companyForms;
