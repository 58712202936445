import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const writeOffs = {
  state: {
    writeOffs: null,
  },

  mutations: {
    setWriteOffs(state, value) {
      state.writeOffs = value;
    },
  },

  actions: {
    getWriteOffXML({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
        .get(baseUrl + "/api/stock_items/technical_documents/goods_write_offs/xml/" + payload.documentable.id, {responseType: 'arraybuffer'})
        .then((response) => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response.data], {type: 'application/xml'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = payload.uuid + '.xml'
            link.click()
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 100)

            commit('clearLoading')
            resolve(response);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                dispatch("destroyToken");
            }
            reject(error);
        });
      });
    },

    getWriteOffPdf({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
        .get(baseUrl + "/api/stock_items/technical_documents/goods_write_offs/pdf/" + payload.documentable.id, {responseType: 'arraybuffer'})
        .then((response) => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data

            console.log()
            link.download =  payload.uuid + '.pdf'

            link.click()
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 100)

            commit('clearLoading')
            resolve(response);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                dispatch("destroyToken");
            }
            reject(error);
        });
      });
    },

    storeWriteOff({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/stock_items/technical_documents/goods_write_offs",
            payload
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            vm.config.globalProperties.$router.push({
              name: "tehnical_documents",
              query: {
                type_id: 7
              }
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateWriteOff({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/stock_items/technical_documents/goods_write_offs/" + payload.document_id,
            payload.data
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izlabots",
            });
            commit("clearLoading");
            dispatch('getSingleTechnicalDocument', payload.id)
            dispatch('removeAllFormsForDisplay')
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    addWriteOffItems({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/stock_items/technical_documents/goods_write_off_items/" + payload.id,
            payload.data
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            dispatch('getSingleTechnicalDocument', payload.document_id)
            dispatch('removeAllFormsForDisplay')
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },   

    updateWriteOffItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/stock_items/technical_documents/goods_write_off_items/" + payload.id,
            payload.data
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Akceptēts",
            });
            commit("clearLoading");
            dispatch('getSingleTechnicalDocument', payload.document_id)
            dispatch('removeAllFormsForDisplay')
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Neizdevās akceptēt",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteWriteOffItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .delete(
            baseUrl +
              "/api/stock_items/technical_documents/goods_write_off_items/" + payload.item_id,
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts izdzēsts",
            });
            commit("clearLoading");
            dispatch('getSingleTechnicalDocument', payload.document_id)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Neizdevās izdzēst",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
  },
  getters: {
    writeOffs(state) {
      return state.writeOffs;
    },
  },
};

export default writeOffs;
