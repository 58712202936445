import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const customers = {
  state: {
    customers: null,
    customerInputData: null,
    customerErrorMessages: {
      email: null,
      reg_nr: null,
      notes: null,
    },
    systemMainCustomer: null,
  },

  mutations: {
    setCustomers(state, customers) {
      state.customers = customers;
    },
    setSystemMainCustomer(state, systemMainCustomer) {
      state.systemMainCustomer = systemMainCustomer;
    },
    setInputData(state, payload) {
      state.customerInputData = payload;
    },
    clearCustomers(state) {
      state.customers = null;
    },
    setCustomerErrorMessages(state, error) {
      state.customerErrorMessages = error;
    },
    clearCustomerErrorMessages(state) {
      state.customerErrorMessages = {
        email: null,
        reg_nr: null,
        notes: null,
      };
    },
  },

  actions: {
    getCustomers({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl + "/api/customers?page=" + page + "&q=" + rootState.search
          )
          .then((response) => {
            commit("setCustomers", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getSystemMainCustomer({ commit, rootState, dispatch }) {
      if (rootState.auth.token == null) {
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/main_customer")
          .then((response) => {
            commit("setSystemMainCustomer", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              vm.config.globalProperties.$router.push({ path: "/login" });
            }
            if (error.response.status === 403) {
              vm.config.globalProperties.$router.push({ name: "Login" });
              dispatch("setSnackbar", {
                color: "red",
                text: "Error",
              });
            }
            reject(error);
          });
      });
    },

    getCustomerInputData({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/customers/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    createNewCustomer({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/" + payload.typeId + "/customers", payload.data)
          .then((response) => {
            dispatch("getCustomers", 1);
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setCustomerErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    clearCustomerErrorMessages({ commit }) {
      commit("clearCustomerErrorMessages");
    },
    clearCustomers({ commit }) {
      commit("clearCustomers");
    },
  },
  getters: {
    customers(state) {
      return state.customers;
    },
    customerInputData(state) {
      return state.customerInputData;
    },
    customerErrorMessages(state) {
      return state.customerErrorMessages;
    },
    systemMainCustomer(state) {
      return state.systemMainCustomer;
    },
  },
};

export default customers;
