import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const stockItems = {
  state: {
    stockItems: null,
  },

  mutations: {
    setStockItems(state, stockItems) {
      state.stockItems = stockItems;
    },
  },

  actions: {
    getStockItems({ commit, rootState, dispatch, state }, params) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock_items?" +
              "page=" +
              params.page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setStockItems", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    updateStockItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/stock_items/" + payload.id, payload.data)
          .then((response) => {
            dispatch("clearEditableItem");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }
            commit("clearLoading");
            reject(error);
          });
      });
    },
    getStockItemReportExcel({ commit, rootState, dispatch, state }) {

      commit('setLoading')

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
      return new Promise((resolve, reject) => {
        axios
            .get(baseUrl + "/api/stock_items_excel", {responseType: 'arraybuffer'})
            .then((response) => {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([response.data], {type: 'application/xlsx'})

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob)
              var link = document.createElement('a')
              link.href = data

              link.download = 'stock_report.xlsx'

              link.click()
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
              }, 100)

              commit('clearLoading')
              resolve(response);
            })
            .catch(error => {
              if (error.response.status === 401) {
                dispatch('destroyToken')
                location.reload();
              }

              commit('clearLoading')
              reject(error)
            })
      });
    },
  },
  getters: {
    stockItems(state) {
      return state.stockItems;
    },
  },
};

export default stockItems;
