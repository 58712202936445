<template>
    <vue-progress-bar></vue-progress-bar>
    <Layout v-if="user && displayContent"/>
    <Login v-if="!user && userLoading"/>

    <vue3-snackbar bottom left :duration="2000" @click="$snackbar.clear()"></vue3-snackbar>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const Layout = defineAsyncComponent(() =>
  import('@/components/Base/Layout'))
const Login = defineAsyncComponent(() =>
  import('@/views/Auth/Login'))

import {mapGetters} from "vuex";

export default {
    components: {
        Layout,
        Login
    },
    data() {
        return {
            userLoading: false,
            displayContent: false
        }
    },
    computed: {
        ...mapGetters({
            loggedIn: 'loggedIn',
            user: 'user',
            mainCustomer: 'systemMainCustomer',
            snackbar: 'snackbar',
            color: 'snackbarColor',
            timeout: 'snackbarTimeout',
            text: 'snackbarText',
        }),
    },
    beforeRouteLeave(to, from, next) {
        history.replaceState({
            ...window.history.state,
            search: this.search,
        }, '')

        this.$store.dispatch('changeSearch', '')
        next()
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                const title = to.meta.title ? `${to.meta.title} BTE CRM` : null;
                document.title = title || 'BTE CRM';

                this.$store.dispatch("setErrors", {});
                this.$store.dispatch('setSidebarOpen', false)
            }
        },
        user() {
          if(this.user && this.mainCustomer) {
              this.displayContent = true
          }
        },
        mainCustomer() {
            if(this.user && this.mainCustomer) {
                this.displayContent = true
            }
        },
        snackbar() {
            if (this.snackbar) {
                this.showSnackbar()
            }
        }
    },
    methods: {
        showSnackbar() {
            const type = this.color == "red" ? "error" : "success"
            this.$snackbar.add({
                type: type,
                text: this.text
            })
        }
    },
    mounted() {
        //  [App.vue specific] When App.vue is finish loading finish the progress bar
        this.$Progress.finish();
    },
    created() {
        const darkMode = localStorage.getItem("darkMode") === 'true' ? true : false;
        if (darkMode) {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }

        this.$store.dispatch('getUser')
            .then(() => {
                this.userLoading = true;
            })
        this.$store.dispatch('getSystemMainCustomer')
        //  [App.vue specific] When App.vue is first loaded start the progress bar
        this.$Progress.start();
        //  hook the progress bar to start before we move router-view
        this.$router.beforeEach((to, from, next) => {
            //  does the page we want to go to have a meta.progress object
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress;
                // parse meta tags
                this.$Progress.parseMeta(meta);
            }
            //  start the progress bar
            this.$Progress.start();
            //  continue to next page
            next();
        });
        //  hook the progress bar to finish after we've finished moving router-view
        this.$router.afterEach((to, from) => {
            //  finish the progress bar
            this.$Progress.finish();
        });
    },
}
</script>

<style>
/* width */
::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Track */
.dark ::-webkit-scrollbar-track {
    background: #2B3340;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0540a6;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #01009a;
}

@media (min-width: 1280px) {
    .grid .mr-10 {
        margin-right: 1rem;
    }
}
</style>