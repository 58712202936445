import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const regions = {
  state: {
    regions: null,
    regionInputData: null,
  },

  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },
    setInputData(state, payload) {
      state.regionInputData = payload;
    },
  },

  actions: {
    getRegions({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/company_settings/regions?page=" +
              page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setRegions", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },
    getRegionInputData({ commit, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/company_settings/regions/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    createNewRegion({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/company_settings/" + payload.branchId + "/regions",
            payload.data
          )
          .then((response) => {
            dispatch("getRegions");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateRegion({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/company_settings/regions/" + payload.id,
            payload
          )
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch("getRegions");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteRegion({ commit, rootState, dispatch, state }, regionId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/company_settings/regions/" + regionId)
          .then((response) => {
            dispatch("getRegions");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    regions(state) {
      return state.regions;
    },
    regionInputData(state) {
      return state.regionInputData;
    },
  },
};

export default regions;
