import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const banks = {
  state: {
    banks: null,
  },

  mutations: {
    setBanks(state, banks) {
      state.banks = banks;
    },
  },

  actions: {
    getBanks({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/company_settings/banks?page=" +
              page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setBanks", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    createNewBank({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/company_settings/banks", payload)
          .then((response) => {
            dispatch("getBanks");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    updateBank({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/company_settings/banks/" + payload.id, payload)
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch("getBanks");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteBank({ commit, rootState, dispatch, state }, bankId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/company_settings/banks/" + bankId)
          .then((response) => {
            dispatch("getBanks");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
  },
  getters: {
    banks(state) {
      return state.banks;
    },
  },
};

export default banks;
