import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const supplierCategories = {
  state: {
    supplierCategories: null,
  },

  mutations: {
    setCategories(state, supplierCategories) {
      state.supplierCategories = supplierCategories;
    },
  },

  actions: {
    getSupplierCategories({ commit, rootState, dispatch, state }, {queryParams, id, loading = true}) {
      commit("setDataLoading", loading)
      return new Promise((resolve, reject) => {
        const rootSearch = { q: rootState.search };
        const mergedQueryParams = Object.assign(rootSearch, queryParams);

        axios
          .get(baseUrl + "/api/stock/suppliers/" + id + "/categories", {
              params: mergedQueryParams,
          })
          .then((response) => {
            commit("setCategories", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },
    updateSupplierCategory({ commit, rootState, dispatch, state }, payload) {
        commit("setLoading");
  
        return new Promise((resolve, reject) => {
          axios
            .patch(baseUrl + "/api/stock/suppliers/" + payload.supplierId + "/categories/" + payload.supplierCategoryId, payload)
            .then((response) => {
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "success",
                text: "Ieraksts izlabots",
              });
  
              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu izveidot neizdevās",
                });
              }
  
              commit("clearLoading");
              reject(error);
            });
        });
      },
  },
  getters: {
    supplierCategories(state) {
      return state.supplierCategories;
    },
  },
};

export default supplierCategories;
