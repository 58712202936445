import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const inventoryItems = {
    state: {
        inventoryItem: null,
        inventoryItems: null,
        inventoryItemInputData: null,
        inventoryErrorMessages: {
            uuid: null,
            serial_nr: null,
            notFound: null,
        },
        inventoryItemServicePositions: null,
    },

    mutations: {
        setInventoryItems(state, inventoryItems) {
            state.inventoryItems = inventoryItems;
        },
        setSingleInventoryItem(state, inventoryItem) {
            state.inventoryItem = inventoryItem;
        },
        clearSingleInventoryItem(state) {
            state.inventoryItem = null;
        },
        setInputData(state, payload) {
            state.inventoryItemInputData = payload;
        },
        setInventoryErrorMessages(state, errors) {
            state.inventoryErrorMessages = errors;
        },
        clearInventoryErrorMessages(state) {
            state.inventoryErrorMessages = {
                uuid: null,
                notFound: null,
            };
        },
        setInventoryItemServicePositions(state, inventoryItemServicePositions) {
            state.inventoryItemServicePositions = inventoryItemServicePositions
        }
    },

    actions: {
        getInventoryItems({ commit, rootState, dispatch, state }, params) {
            commit("setDataLoading", true);
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        baseUrl +
                            "/api/stock/inventory_items?" +
                            "page=" +
                            params.page +
                            "&q=" +
                            rootState.search +
                            "&branch_id=" +
                            params.selectedBranch +
                            "&filter_inventory_items_by_status=" +
                            params.filter
                    )
                    .then((response) => {
                        commit("setInventoryItems", response.data);
                        commit("setDataLoading", false);
                        resolve(response);
                    })
                    .catch((error) => {
                        ""
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        commit("setDataLoading", false);
                        reject(error);
                    });
            });
        },

        getSingleInventoryItem(
            { commit, rootState, dispatch, state },
            payload
        ) {
            return new Promise((resolve, reject) => {
                commit("clearSingleInventoryItem");

                axios
                    .get(baseUrl + "/api/stock/inventory_items/" + payload.uuid)
                    .then((response) => {
                        commit("setSingleInventoryItem", response.data);
                        commit("clearInventoryErrorMessages");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        if (error.response.status === 404) {
                            commit("setInventoryErrorMessages", {
                                notFound: "Inventārs ar šādu UUID neeksistē",
                            });
                        }
                        reject(error);
                    });
            });
        },

        createNewInventoryItems(
            { commit, rootState, dispatch, state },
            payload
        ) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/stock/inventory_items", payload)
                    .then((response) => {
                        vm.config.globalProperties.$router.push({
                            name: "inventory_items",
                        });
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Ieraksts izveidots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izveidot neizdevās",
                            });
                        }
                        commit("clearLoading");
                        reject(error);
                    });
            });
        },
        updateInventoryItem({ commit, rootState, dispatch, state }, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .patch(
                        baseUrl +
                            "/api/stock/inventory_items/" +
                            payload.data.id,
                        payload.data
                    )
                    .then((response) => {
                        dispatch("clearEditableItem");
                        dispatch("getInventoryItems", payload.params);
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit(
                                "setInventoryErrorMessages",
                                error.response.data.errors
                            );
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu neizdevās izlabot",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        updateSingleInventoryItem(
            { commit, rootState, dispatch, state },
            payload
        ) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .patch(
                        baseUrl + "/api/stock/inventory_items/" + payload.id,
                        payload
                    )
                    .then((response) => {
                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleInventoryItem", state.inventoryItem);
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit(
                                "setInventoryErrorMessages",
                                error.response.data.errors
                            );
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu neizdevās izlabot",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        updateInventoryItemOptionBasePrices(
            { commit, rootState, dispatch, state },
            payload
        ) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(
                        baseUrl +
                            "/api/stock/inventory_items/" +
                            state.inventoryItem.id +
                            "/option_base_prices",
                        payload
                    )
                    .then((response) => {
                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleInventoryItem", state.inventoryItem);
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit(
                                "setInventoryErrorMessages",
                                error.response.data.errors
                            );
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu neizdevās izlabot",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        updateInventoryItemDetails(
            { commit, rootState, dispatch, state },
            payload
        ) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .patch(
                        baseUrl + "/api/stock/inventory_items/" + payload.id,
                        payload
                    )
                    .then((response) => {
                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleInventoryItem", payload);
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "blue",
                            text: "Ieraksts izlabots",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit(
                                "setInventoryErrorMessages",
                                error.response.data.errors
                            );
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu neizdevās izlabot",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        deleteInventoryItem({ commit, rootState, dispatch, state }, itemId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + "/api/stock/inventory_items/" + itemId)
                    .then((response) => {
                        dispatch("getInventoryItems");
                        dispatch("setSnackbar", {
                            color: "red",
                            text: "Ieraksts dzēsts",
                        });
                        vm.config.globalProperties.$Progress.finish();
                        resolve(response);
                    })
                    .catch((error) => {
                        ""
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        clearSingleInventoryItem({ commit }) {
            commit("clearSingleInventoryItem");
        },
        clearInventoryErrorMessages({ commit }) {
            commit("clearInventoryErrorMessages");
        },
        getInventoryItemServicePositions({ commit, rootState, dispatch, state }, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        baseUrl +
                            "/api/stock/inventory_item_service_positions/"+ params.inventoryItem + "/" + params.serviceApplication
                    )
                    .then((response) => {
                        commit("setInventoryItemServicePositions", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        ""
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        commit("setDataLoading", false);
                        reject(error);
                    });
            });
        },
    },
    getters: {
        inventoryItem(state) {
            return state.inventoryItem;
        },
        inventoryItems(state) {
            return state.inventoryItems;
        },
        inventoryItemInputData(state) {
            return state.inventoryItemInputData;
        },
        inventoryErrorMessages(state) {
            return state.inventoryErrorMessages;
        },
        inventoryItemServicePositions(state) {
            return state.inventoryItemServicePositions;
        },
    },
};

export default inventoryItems;
