import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const createProcurementInvoices = {
  state: {
    invoiceableSuppliers: null,
    procurementInvoiceInputData: null,
  },

  mutations: {
    setInvoiceableSuppliers(state, invoiceableSuppliers) {
      state.invoiceableSuppliers = invoiceableSuppliers;
    },
    setInputData(state, procurementInvoiceInputData) {
      state.procurementInvoiceInputData = procurementInvoiceInputData;
    },
  },

  actions: {
    getInvoiceableSuppliers({ commit, rootState, dispatch, state }, params) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/procurement_invoices/invoiceables?" +
              "page=" +
              params.page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setInvoiceableSuppliers", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getProcurementInvoicesInputData(
      { commit, rootState, dispatch },
      supplierId
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/procurement_invoices/create/" + supplierId)
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }

            if (error.response.status === 404) {
              vm.config.globalProperties.$router.push({
                name: "procurement_invoices",
              });
            }

            reject(error);
          });
      });
    },

    createNewProcurementInvoice(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/stock/procurement_invoices", payload)
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            vm.config.globalProperties.$router.push({
              path: "/stock/procurement_invoices",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    clearProcurementInvoiceInputData({ commit }) {
      commit("setInputData", null);
    },
  },
  getters: {
    invoiceableSuppliers(state) {
      return state.invoiceableSuppliers;
    },
    procurementInvoiceInputData(state) {
      return state.procurementInvoiceInputData;
    },
  },
};

export default createProcurementInvoices;
