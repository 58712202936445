import { vm } from "@/main";
import axios from "axios";
import stockBalance from "./stockBalance";
import writeOffs from "./writeOffs";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const tehnicalDocuments = {
  modules: {
    stockBalance,
    writeOffs,
  },

  state: {
    technicalDocuments: null,
    technicalDocument: null,
    stockList: null,
  },

  mutations: {
    setTechnicalDocuments(state, technicalDocuments) {
      state.technicalDocuments = technicalDocuments;
    },
    setSingleTechnicalDocument(state, technicalDocument) {
      state.technicalDocument = technicalDocument;
    },
    setStockList(state, stockList) {
      state.stockList = stockList;
    },
  },

  actions: {
    getTechnicalDocuments({ commit, rootState, dispatch, state }, page) {
      commit("setSingleTechnicalDocument", null);
      commit("setDataLoading", true);

      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock_items/technical_documents?page=" +
              page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setTechnicalDocuments", response.data);
            commit("setDataLoading", false);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false);
            reject(error);
          });
      });
    },
    clearSingleTechnicalDocument({ commit, rootState, dispatch, state }) {
      commit("setSingleTechnicalDocument", null);
    },

    filterTehnicalDocuments(
      { commit, rootState, dispatch, state },
      queryParams
    ) {
      dispatch("setLoading");
      commit("setDataLoading", true);

      return new Promise((resolve, reject) => {
        const rootSearch = { q: rootState.search };
        const mergedQueryParams = Object.assign(rootSearch, queryParams);

        axios
          .get(baseUrl + "/api/stock_items/technical_documents", {
            params: mergedQueryParams,
          })
          .then((response) => {
            commit("setTechnicalDocuments", response.data);
            dispatch("clearLoading");
            commit("setDataLoading", false);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            dispatch("clearLoading");
            commit("setDataLoading", false);
            reject(error);
          });
      });
    },

    getSingleTechnicalDocument(
      { commit, rootState, dispatch, state },
      documentId
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock_items/technical_documents/" + documentId)
          .then((response) => {
            commit("setSingleTechnicalDocument", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    getStockList({ commit, rootState, dispatch, state }, productId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock_items/" + productId)
          .then((response) => {
            commit("setStockList", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    storeInventoryItem({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/stock_items/technical_documents/inventory_items",
            payload
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            vm.config.globalProperties.$router.push({
              name: "tehnical_documents",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteDocument({ commit, rootState, dispatch, state }, itemId) {
      return new Promise((resolve, reject) => {
        axios
            .delete(baseUrl + "/api/stock_items/technical_documents/" + itemId)
            .then((response) => {

              dispatch("setSnackbar", {
                color: "red",
                text: "Ieraksts dzēsts",
              });
              vm.config.globalProperties.$Progress.finish();
              vm.config.globalProperties.$router.push("/stock/tehnical_documents");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }
              reject(error);
            });
      });
    },

    updateGoodsRemoval({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/stock_items/technical_documents/goods_removals/" +
              payload.goodsRemovalId,
            payload.data
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();

            dispatch("getSingleTechnicalDocument", payload.documentId);

            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    getGoodsRemovalXml({ commit, rootState, dispatch, state }, goodsRemoval) {
      commit("setLoading");

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock_items/technical_documents/goods_removals/xml/" +
              goodsRemoval.id,
            { responseType: "arraybuffer" }
          )
          .then((response) => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response.data], {
              type: "application/xml",
            });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = "precu_parvietosana.xml";
            link.click();
            setTimeout(function() {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
  },
  getters: {
    technicalDocuments(state) {
      return state.technicalDocuments;
    },

    technicalDocument(state) {
      return state.technicalDocument;
    },

    stockList(state) {
      return state.stockList;
    },
  },
};

export default tehnicalDocuments;
