import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const orderItems = {
  state: {},

  mutations: {},

  actions: {
    updateOrderItem({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/" +
              payload.orderId +
              "/order_items/" +
              payload.orderItemId,
            payload.data
          )
          .then((response) => {
            dispatch("getSingleOrder", payload.orderId);
            dispatch("clearEditableItem");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            resolve(response);
            vm.config.globalProperties.$Progress.finish();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }

            if (error.response.status === 403 || error.response.status === 422) {
              dispatch("getSingleOrder", payload.orderId);
              dispatch("clearEditableItem");
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu aizliegts labot",
              });
              vm.config.globalProperties.$Progress.finish();
            }

            reject(error);
          });
      });
    },

    updateOrderStatus({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/order_status/" +payload.order_id,
            payload.data
          )
          .then((response) => {
            dispatch("getSingleOrder", payload.order_id);
            dispatch("clearEditableItem");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            resolve(response);
            vm.config.globalProperties.$Progress.finish();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }

            if (error.response.status === 403 || error.response.status === 422) {
              dispatch("getSingleOrder", payload.orderId);
              dispatch("clearEditableItem");
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu aizliegts labot",
              });
              vm.config.globalProperties.$Progress.finish();
            }

            reject(error);
          });
      });
    },

    revertStatusToAccepted({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .get(
                baseUrl +
                "/api/order_revert_to_accepted/" +payload.order_id,
                payload.data
            )
            .then((response) => {
              dispatch("getSingleOrder", payload.order_id);
              dispatch("clearEditableItem");
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });
              resolve(response);
              vm.config.globalProperties.$Progress.finish();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }

              if (error.response.status === 403 || error.response.status === 422) {
                dispatch("getSingleOrder", payload.orderId);
                dispatch("clearEditableItem");
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu aizliegts labot",
                });
                vm.config.globalProperties.$Progress.finish();
              }

              reject(error);
            });
      });
    },

    createOrUpdateOrderInvoice({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post(
                baseUrl +
                "/api/orders/invoice/" +payload.order_id,
                payload.data
            )
            .then((response) => {
              dispatch("getSingleOrder", payload.order_id);
              dispatch("clearEditableItem");
              dispatch("removeAllFormsForDisplay");
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });
              resolve(response);
              vm.config.globalProperties.$Progress.finish();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }

              if (error.response.status === 403 || error.response.status === 422) {
                dispatch("getSingleOrder", payload.orderId);
                dispatch("clearEditableItem");
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu aizliegts labot",
                });
                vm.config.globalProperties.$Progress.finish();
              }

              reject(error);
            });
      });
    },
    updateOrUpdateOrderInvoice({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .patch(
                baseUrl +
                "/api/orders/invoice/" +payload.order_id,
                payload.data
            )
            .then((response) => {
              dispatch("getSingleOrder", payload.order_id);
              dispatch("clearEditableItem");
              dispatch("removeAllFormsForDisplay");
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });
              resolve(response);
              vm.config.globalProperties.$Progress.finish();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }

              if (error.response.status === 403 || error.response.status === 422) {
                dispatch("getSingleOrder", payload.orderId);
                dispatch("clearEditableItem");
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu aizliegts labot",
                });
                vm.config.globalProperties.$Progress.finish();
              }

              reject(error);
            });
      });
    },

    deleteOrderItem({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            baseUrl +
              "/api/" +
              payload.orderId +
              "/order_items/" +
              payload.orderItemId
          )
          .then((response) => {
            dispatch("getSingleOrder", payload.orderId);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts izdzēsts",
            });
            resolve(response);
            vm.config.globalProperties.$Progress.finish();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }

            if (error.response.status === 403) {
              dispatch("getSingleOrder", payload.orderId);
              dispatch("clearEditableItem");
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu aizliegts dzēst",
              });
              vm.config.globalProperties.$Progress.finish();
            }

            reject(error);
          });
      });
    },
  },
  getters: {},
};

export default orderItems;
