import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const branchContacts = {
  state: {
    showAddBranchContactForm: false,
    editableBranchContact: {},
  },

  mutations: {
    setEditableBranchContact(state, contact) {
      state.editableBranchContact = contact;
    },
    clearEditableBranchContact(state) {
      state.editableBranchContact = {};
    },
    hideBranchContactForms(state) {
      state.editableBranchContact = {};
    },
  },

  actions: {
    createNewBranchContact({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/" + payload.branchId + "/branch_contacts",
            payload.data
          )
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleBranch", payload.branchId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts pievienots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izveidot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    attachExistingBranchContact({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .get(
                baseUrl + "/api/" + payload.branchId + "/attach_branch_contacts/" + payload.contactId,
                payload.data
            )
            .then((response) => {
              dispatch("removeAllFormsForDisplay");
              dispatch("getSingleBranch", payload.branchId);
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "green",
                text: "Ieraksts pievienots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu neizdevās izveidot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },

    updateBranchContact({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/branch_contacts/" + payload.data.id,
            payload.data
          )
          .then((response) => {
            dispatch("hideBranchContactForms");
            dispatch("getSingleBranch", payload.branchId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteBranchContact({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/" + payload.branchId + "/branch_contacts/" + payload.contactId)
          .then((response) => {
            dispatch("getSingleBranch", payload.branchId);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            if (error.response.status === 403) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Šo ierakstu dzēsts ir aizliegts",
              });
            }
            reject(error);
          });
      });
    },
    setEditableBranchContact({ commit }, contact) {
      commit("setEditableBranchContact", contact);
    },
    clearEditableBranchContact({ commit }) {
      commit("clearEditableBranchContact");
    },
    hideBranchContactForms({ commit }) {
      commit("hideBranchContactForms");
    },
  },
  getters: {
    editableBranchContact(state) {
      return state.editableBranchContact;
    },
  },
};

export default branchContacts;
