import orders from "@/store/Orders/orders";
import roles from "@/store/Roles/roles";
import catalogParams from "@/store/Settings/CatalogParams/catalogParams";
import companyForms from "@/store/Settings/CompanySettings/companyForms";
import warehouses from "@/store/Settings/CompanySettings/warehouses";
import Vue from "vue";
import Vuex from "vuex";
import auth from "./Auth/auth";
import branch from "./Customers/Branches/branch";
import branchContacts from "./Customers/Branches/BranchContacts/branchContacts";
import branches from "./Customers/Branches/branches";
import customer from "./Customers/customer";
import customerContacts from "./Customers/CustomerDetails/customerContacts";
import customers from "./Customers/customers";
import invoices from "./Invoices/invoices";
import catalogItems from "./Settings/Catalog/catalogItems";
import categories from "./Settings/Categories/categories";
import categoryOptionItems from "./Settings/Categories/categoryOptionItems";
import categoryOptions from "./Settings/Categories/categoryOptions";
import banks from "./Settings/CompanySettings/banks";
import measureUnits from "./Settings/CompanySettings/measureUnits";
import regions from "./Settings/CompanySettings/regions";
import stock from "./Stock/stock";
import users from "./Users/users";
import catalogItemUnits from "./CatalogItemUnits/catalogItemUnits";
import celebrations from "./Settings/Celebrations";
import messages from "./Messages";
import creditNotes from "./CreditNotes/creditNotes";
import { createStore } from "vuex";
import { vm } from "@/main";

// var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

export default createStore({
  modules: {
    auth,
    users,
    catalogItems,
    categories,
    categoryOptions,
    categoryOptionItems,
    customers,
    customer,
    branches,
    branch,
    creditNotes,
    customerContacts,
    branchContacts,
    banks,
    celebrations,
    measureUnits,
    companyForms,
    regions,
    stock,
    invoices,
    catalogParams,
    orders,
    warehouses,
    roles,
    catalogItemUnits,
    messages,
  },
  state: {
    drawer: false,
    search: "",
    errorStatus: false,
    errorMessage: null,
    snackbar: false,
    snackbarColor: "",
    snackbarTimeout: 5000,
    snackbarText: "",
    showEditModal: false,
    showAddModal: false,
    editableItem: {},
    formsForDisplay: [],
    loading: false,
    currentPage: "/",
    sidebarOpen: false,
    errors: {},
    dataLoading: false,
    darkModeStatus: true,
  },

  mutations: {
    changeDrawer(state) {
      state.drawer = !state.drawer;
    },
    addFormForDisplay(state, formName) {
      state.formsForDisplay.push(formName);
    },
    removeAllFormsForDisplay(state) {
      state.formsForDisplay = [];
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setSnackbar(state, payload) {
      state.snackbar = true;
      state.snackbarColor = payload.color;
      state.snackbarText = payload.text;
    },
    setErrorMessage(state, message) {
      state.errorStatus = true;
      state.errorMessage = message;
    },
    resetErrors(state) {
      state.errorStatus = false;
      state.errorMessage = null;
    },
    clearSnackbar(state) {
      state.snackbar = false;
      state.snackbarColor = "";
      state.snackbarText = "";
    },
    showAddModal(state) {
      state.showAddModal = true;
    },
    hideAddModal(state) {
      state.showAddModal = false;
    },
    showEditModal(state, item) {
      state.editableItem = item;
      state.showEditModal = true;
    },
    hideEditModal(state) {
      state.showEditModal = false;
      state.editableItem = {};
    },
    clearEditableItem(state) {
      state.editableItem = {};
    },
    setEditableItem(state, item) {
      state.editableItem = item;
    },
    clearLoading(state) {
      state.loading = false;
    },
    setLoading(state) {
      state.loading = true;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setSidebarOpen(state, value) {
      state.sidebarOpen = value;
    },
    addErrors(state, value) {
      state.errors[value.name] = value.error;
    },
    setErrors(state, value) {
      state.errors = value;
    },
    setDataLoading(state, value) {
      state.dataLoading = value
    },
    setDarkModeStatus(state, value) {
      state.darkModeStatus = value
    }
  },

  actions: {
    changeDrawer({ commit }) {
      commit("changeDrawer");
    },
    resetSearch(context) {
      const defaultSearch = "";
      context.commit("setSearch", defaultSearch);
    },
    changeSearch({ commit, state }, payload) {
      localStorage.setItem("q", payload);

      if (localStorage.getItem("q") == "") {

        localStorage.removeItem("q");

        const url =  window.location.pathname;
        vm.config.globalProperties.$router.push(url);
      }

      if (history.pushState && payload) {
        let searchParams = new URLSearchParams(window.location.search);
        if (localStorage.getItem("q") !== payload) {
          searchParams.delete("page");
        }
        searchParams.set("q", payload);
        let newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          searchParams.toString();

          const url =  window.location.pathname + "?" + searchParams.toString();
          vm.config.globalProperties.$router.push(url);
      }

      commit("setSearch", payload);
      // localStorage.setItem("q", payload);
    },
    resetErrors({ commit }) {
      commit("resetErrors");
    },
    setSnackbar({ commit }, payload) {
      commit("setSnackbar", payload);
      setTimeout(() => {
        commit("clearSnackbar");
      }, 5000);
    },
    clearSnackbar({ commit }) {
      commit("clearSnackbar");
    },
    showEditModal({ commit }, item) {
      commit("showEditModal", item);
    },
    showAddModal({ commit }) {
      commit("showAddModal");
    },
    hideAddModal({ commit }) {
      commit("hideAddModal");
    },
    hideEditModal({ commit }) {
      commit("hideEditModal");
    },
    clearEditableItem({ commit }) {
      commit("clearEditableItem");
      commit("setErrors", {});
    },
    setEditableItem({ commit }, item) {
      commit("setEditableItem", item);
      commit("setErrors", {});
    },
    addFormForDisplay({ commit }, formName) {
      commit("addFormForDisplay", formName);
    },
    removeAllFormsForDisplay({ commit }) {
      commit("removeAllFormsForDisplay");
    },
    clearLoading({ commit }) {
      commit("clearLoading");
    },
    setLoading({ commit }) {
      commit("setLoading");
    },
    setCurrentPage({ commit }, page) {
      commit("setCurrentPage", page);
    },
    setSidebarOpen({ commit }, value) {
      commit("setSidebarOpen", value);
    },
    addErrors({ commit }, value) {
      commit("addErrors", value);
    },
    setErrors({ commit }, value) {
      commit("setErrors", value);
    },
    setDataLoading({ commit }, value) {
      commit("setDataLoading", value)
    },
  },
  getters: {
    drawer(state) {
      return state.drawer;
    },
    search(state) {
      return state.search;
    },
    errorStatus(state) {
      return state.errorStatus;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    snackbar(state) {
      return state.snackbar;
    },
    snackbarColor(state) {
      return state.snackbarColor;
    },
    snackbarTimeout(state) {
      return state.snackbarTimeout;
    },
    snackbarText(state) {
      return state.snackbarText;
    },
    showEditModal(state) {
      return state.showEditModal;
    },
    showAddModal(state) {
      return state.showAddModal;
    },
    editableItem(state) {
      return state.editableItem;
    },
    formsForDisplay(state) {
      return state.formsForDisplay;
    },
    loading(state) {
      return state.loading;
    },
    currentPage(state) {
      return state.currentPage;
    },
    sidebarOpen(state) {
      return state.sidebarOpen;
    },
    errors(state) {
      return state.errors;
    },
    dataLoading(state) {
      return state.dataLoading;
    },
    darkModeStatus(state) {
      return state.darkModeStatus
    },
  },
});
