import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const customerPaymentDetails = {
  state: {
    paymentDetailInputData: null,
  },

  mutations: {
    setPaymentDetailInputData(state, payload) {
      state.paymentDetailInputData = payload;
    },
  },

  actions: {
    getPaymentDetailInputData({ commit, rootState, dispatch }, customerId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl + "/api/" + customerId + "/customer_payment_details/create"
          )
          .then((response) => {
            commit("setPaymentDetailInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewCustomerPaymentDetails(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/" +
              payload.customerId +
              "/customer_payment_details",
            payload.data
          )
          .then((response) => {
            dispatch("getCustomer", payload.customerId);
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setCustomerErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updatePaymentDetails({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/customer_payment_details/" + payload.id,
            payload
          )
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getCustomer", rootState.customer.customer.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              commit("setCustomerErrorMessages", error.response.data.errors);
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
  },
  getters: {
    paymentDetailInputData(state) {
      return state.paymentDetailInputData;
    },
  },
};

export default customerPaymentDetails;
