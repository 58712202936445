import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const messages = {
  state: {
    messages: null,
    messagesLoading: false,
  },

  mutations: {
    setMessages(state, messages) {
      state.messages = messages;
    },
    setMessagesLoading(state, value) {
      state.messagesLoading = value;
    },
  },

  actions: {
    getMessages({ commit, rootState, dispatch, state }, data) {
      commit("setMessagesLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/dashboard_components/" + data.id + "?page=" + data.page)
          .then((response) => {
            commit("setMessages", response.data);
            commit("setMessagesLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setMessagesLoading", false)
            reject(error);
          });
      });
    },
    createNewMessage({ commit, rootState, dispatch, state }, data) {
      commit("setMessagesLoading", true);
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/messages", data.data)
          .then((response) => {
            dispatch("getMessages", {id: data.id, page: 1});

            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            commit("clearUserErrorMessages");
            dispatch("setSnackbar", {
              color: "green",
              text: "Lietotājs izveidots",
            });
            commit("setMessagesLoading", false)

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setUserErrorMessages", error.response.data.errors);
            }

            commit("setMessagesLoading", false)
            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateMessage({ commit, rootState, dispatch, state }, data) {
      commit("setMessagesLoading", true)

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/messages/" + data.data.id, data.data)
          .then((response) => {

            dispatch("getMessages", {id: data.id, page: 1});

            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ziņa saglabāta",
            });
            dispatch('clearEditableItem')
            commit("setMessagesLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ziņu neizdevās saglabāt",
              });
            }
            
            commit("setMessagesLoading", false)
            reject(error);
          });
      });
    },
    deleteMessage({ commit, rootState, dispatch, state }, data) {
      commit("setMessagesLoading", true)
      return new Promise((resolve, reject) => {
        axios
        .delete(baseUrl + "/api/messages/" + data.data.id)
          .then((response) => {
            dispatch("getMessages", {id: data.id, page: 1});

            dispatch("setSnackbar", {
              color: "red",
              text: "Ziņa dzēsta",
            });
            vm.config.globalProperties.$Progress.finish();
            commit("setMessagesLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setMessagesLoading", false)
            reject(error);
          });
      });
    },
  },
  getters: {
    messages(state) {
      return state.messages;
    },
    messagesLoading(state) {
      return state.messagesLoading
    },
  },
};

export default messages;
