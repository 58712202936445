import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const procurements = {
  state: {
    procurement: null,
    procurements: null,
    procurementInputData: null,
  },

  mutations: {
    setProcurements(state, procurements) {
      state.procurements = procurements;
    },
    setSingleProcurement(state, procurement) {
      state.procurement = procurement;
    },
    setInputData(state, payload) {
      state.procurementInputData = payload;
    },
  },

  actions: {
    getProcurements({ commit, rootState, dispatch, state }, params) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/procurements?" +
              "page=" +
              params.page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setProcurements", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    filterProcurements({ commit, rootState, dispatch, state }, queryParams) {
      dispatch("setLoading");

      return new Promise((resolve, reject) => {
        commit("setDataLoading", true)
        const rootSearch = { q: rootState.search };
        const mergedQueryParams = Object.assign(rootSearch, queryParams);

        axios
          .get(baseUrl + "/api/stock/procurements", {
            params: mergedQueryParams,
          })
          .then((response) => {
            commit("setProcurements", response.data);
            dispatch("clearLoading");
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            dispatch("clearLoading");
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getSingleProcurement(
      { commit, rootState, dispatch, state },
      procurementId
    ) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/procurements/" + procurementId)
          .then((response) => {
            commit("setSingleProcurement", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getProcurementsInputData({ commit, rootState, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/procurements/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewProcurement({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/stock/procurements", payload)
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            vm.config.globalProperties.$router.push({ name: "procurements" });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    createNewProcurementFromExcel({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/stock/procurement_from_excel", payload)
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");
            vm.config.globalProperties.$router.push({ name: "procurements" });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateProcurement({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/stock/procurements/" + payload.id, payload)
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch("getSingleProcurement", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteProcurement({ commit, rootState, dispatch, state }, itemId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/stock/procurements/" + itemId)
          .then((response) => {
            dispatch("getProcurements", 1);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              dispatch("setSnackbar", {
                color: "red",
                text: "Šo ieprikumu nedrīkst dzēst",
              });
              vm.config.globalProperties.$Progress.fail()
            }
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    addProcurementItems({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/stock/procurements/" +
              payload.procurementId +
              "/procurement_items/",
            payload.data
          )
          .then((response) => {
            dispatch("getSingleProcurement", payload.procurementId);
            dispatch("removeAllFormsForDisplay");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateProcurementItem({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/stock/procurements/" +
              payload.procurementId +
              "/procurement_items/" +
              payload.procurementItemId,
            payload.data
          )
          .then((response) => {
            dispatch("getSingleProcurement", payload.procurementId);
            dispatch("clearEditableItem");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    deleteProcurementItem({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            baseUrl +
              "/api/stock/procurements/" +
              payload.procurementId +
              "/procurement_items/" +
              payload.procurementItemId
          )
          .then((response) => {
            dispatch("getSingleProcurement", payload.procurementId);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts izdzēsts",
            });
            resolve(response);
            vm.config.globalProperties.$Progress.finish();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    deleteProcurementItemUnitItems({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .delete(baseUrl + "/api/stock/procurements/procurement_item_units/" + payload.procurementItemId)
            .then((response) => {
              dispatch("getSingleProcurement", payload.procurementId);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ieraksts izdzēsts",
              });
              resolve(response);
              vm.config.globalProperties.$Progress.finish();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }
              reject(error);
            });
      });
    },

    addProcurementItemUnitItems({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post(baseUrl + "/api/stock/procurements/procurement_item_units/" + payload.procurementItemId,
                payload.data)
            .then((response) => {
              dispatch("getSingleProcurement", payload.procurementId);
              dispatch("setSnackbar", {
                color: "green",
                text: "Ieraksts pievienots",
              });
              resolve(response);
              vm.config.globalProperties.$Progress.finish();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }
              reject(error);
            });
      });
    },

    splitProcurement({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/stock/procurements/" +
              payload.procurementId +
              "/split",
            {
              procurement_items: payload.procurementItems,
            }
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izlabot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    clearSingleProcurement({ commit }) {
      commit("setSingleProcurement", null);
    },
    cancelProcurement(
      { commit, rootState, dispatch, state },
      procurementId
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/procurements/" + procurementId + "/revert")
          .then((response) => {
            dispatch("getSingleProcurement", procurementId);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    acceptProcurement({ commit, rootState, dispatch, state }, procurementId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/procurements/" + procurementId + "/accept")
          .then((response) => {
            dispatch("getSingleProcurement", procurementId);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getItemUniqueItems({ commit, rootState, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock_item_unique_items/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    fetchMultipleCatalogItems({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/fetch_multiple_catalog_items", payload
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewProcurementReturnAct({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/stock/procurement_return_acts/" + payload.procurementId,
            payload.data
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");

            dispatch('getSingleProcurement', payload.procurementId)
            dispatch('removeAllFormsForDisplay')
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    getProcurementXML({ commit, rootState, dispatch, state }, id) {

      commit('setLoading')

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
      return new Promise((resolve, reject) => {
          axios
              .get(baseUrl + "/api/stock/procurement_xml/" + id ,  {responseType: 'arraybuffer'})
              .then((response) => {
                  // It is necessary to create a new blob object with mime-type explicitly set
                  // otherwise only Chrome works like it should
                  var newBlob = new Blob([response.data], {type: 'application/xml'})

                  // IE doesn't allow using a blob object directly as link href
                  // instead it is necessary to use msSaveOrOpenBlob
                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(newBlob)
                      return
                  }

                  // For other browsers:
                  // Create a link pointing to the ObjectURL containing the blob.
                  const data = window.URL.createObjectURL(newBlob)
                  var link = document.createElement('a')
                  link.href = data
                  link.download = 'documents_' +  id + '.xml'
                  link.click()
                  setTimeout(function () {
                      // For Firefox it is necessary to delay revoking the ObjectURL
                      window.URL.revokeObjectURL(data)
                  }, 100)

                  commit('clearLoading')
                  resolve(response);
              })
              .catch(error => {
                  if (error.response.status === 401) {
                      dispatch('destroyToken')
                      location.reload();
                  }

                  commit('clearLoading')
                  reject(error)
              })
      });
    },

    uploadProcurementImages({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post(baseUrl + "/api/stock/procurement_images/" + payload.id, payload.files)
            .then((response) => {
              dispatch("removeAllFormsForDisplay");
              dispatch("getSingleProcurement", payload.id);
              vm.config.globalProperties.$Progress.finish();
              dispatch("clearLoading");
              dispatch("setSnackbar", {
                color: "green",
                text: "Bildes saglabātas",
              });

              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                commit(
                    "setBranchImageUploadError",
                    "Failam jābūt bildei un MAX 8 MB"
                );
                dispatch("clearLoading");
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu neizdevās izlabot",
                });
              }
              reject(error);
            });
      });
    },

    deleteProcurementImage({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
            .delete(baseUrl + "/api/files/" + payload.id)
            .then((response) => {
              //dispatch("removeAllFormsForDisplay");
              //dispatch("getSingleInvoice", payload.invoiceId);
              vm.config.globalProperties.$Progress.finish();
              //dispatch("clearEditableItem");
              dispatch("setSnackbar", {
                color: "red",
                text: "Bilde dzēsta",
              });

              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              reject(error);
            });
      });
    },

    updateProcurementAdditionalDetails({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .patch(baseUrl + "/api/stock/procurements_additional_details/" + payload.id, payload.data)
            .then((response) => {
              dispatch("clearEditableItem");
              dispatch("getSingleProcurement", payload.id);
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "blue",
                text: "Ieraksts izlabots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Ierakstu neizdevās izlabot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },

    getProcurementsReportExcel({ commit, rootState, dispatch, state }, payload) {

      commit('setLoading')

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
      return new Promise((resolve, reject) => {
        axios
            .get(baseUrl +
                "/api/stock/procurements_report_excel?from=" + payload.from +
                '&to=' + payload.to +
                '&q=' + rootState.search +
                '&is_accepted=' + payload.is_accepted,
                {responseType: 'arraybuffer'})
            .then((response) => {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([response.data], {type: 'application/xlsx'})

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob)
              var link = document.createElement('a')
              link.href = data

              link.download = 'procurements_report.xlsx'

              link.click()
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
              }, 100)

              commit('clearLoading')
              resolve(response);
            })
            .catch(error => {
              if (error.response.status === 401) {
                dispatch('destroyToken')
                location.reload();
              }

              commit('clearLoading')
              reject(error)
            })
      });
    },

    getProcurementsReportPdf({ commit, rootState, dispatch, state }, payload) {

      commit('setLoading')

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
      return new Promise((resolve, reject) => {
        axios
            .get(
                baseUrl +
                "/api/stock/procurements_report_pdf?from=" + payload.from +
                '&to=' + payload.to +
                '&q=' + rootState.search +
                '&is_accepted=' + payload.is_accepted,
                {responseType: 'arraybuffer'})
            .then((response) => {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([response.data], {type: 'application/pdf'})

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob)
              var link = document.createElement('a')
              link.href = data

              link.download =  'procurements_report.pdf'

              link.click()
              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
              }, 100)

              commit('clearLoading')
              resolve(response);
            })
            .catch(error => {
              if (error.response.status === 401) {
                dispatch('destroyToken')
                location.reload();
              }

              commit('clearLoading')
              reject(error)
            })
      });
    },
  },
  getters: {
    procurement(state) {
      return state.procurement;
    },
    procurements(state) {
      return state.procurements;
    },
    procurementInputData(state) {
      return state.procurementInputData;
    },
  },
};

export default procurements;
