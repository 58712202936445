import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const catalogItemParamValues = {
  state: {
    catalogItemParamValuesInputData: null,
  },

  mutations: {
    setInputData(state, payload) {
      state.catalogItemParamValuesInputData = payload;
    },
  },

  actions: {
    getCatalogItemParamValuesInputData(
      { commit, rootState, dispatch },
      catalogItemId
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/catalog_item_params/create/" + catalogItemId)
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewCatalogItemParamValues(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/catalog_item_param_values/" + payload.catalogItemId,
            payload.data
          )
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("clearEditableItem");
            dispatch("getSingleCatalogItem", payload.catalogItemId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }
            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateCatalogItemParamValuesWithType(
      { commit, rootState, dispatch, state },
      payload
    ) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl +
              "/api/catalog_item_param_values_with_type/" +
              payload.catalogItemId +
              "/" +
              payload.catalogParamId,
            payload.data
          )
          .then((response) => {
            dispatch("clearEditableItem");
            dispatch(
              "getSingleCatalogItem",
              vm.config.globalProperties.$route.params.catalogItemId
            );
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteCatalogItemParamValues(
      { commit, rootState, dispatch, state },
      payload
    ) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            baseUrl +
              "/api/catalog_item_param_values_with_type/" +
              payload.catalogItemId +
              "/" +
              payload.catalogParamId
          )
          .then((response) => {
            dispatch("getSingleCatalogItem", vm.$route.params.catalogItemId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }
            commit("clearLoading");
            reject(error);
          });
      });
    },
  },
  getters: {
    catalogItemParamValuesInputData(state) {
      return state.catalogItemParamValuesInputData;
    },
  },
};

export default catalogItemParamValues;
