import {vm} from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const catalogItemUnits = {
    state: {
        catalogItemUnits: null,
        catalogItemUnit: null,
    },

    mutations: {
        setCatalogItemUnits(state, data) {
            state.catalogItemUnits = data;
        },
        setCatalogItemUnit(state, data) {
            state.catalogItemUnit = data;
        },
    },

    actions: {
        getCatalogItemUnits({commit, rootState, dispatch, state}, queryParams) {
            dispatch("setLoading");
            commit("setDataLoading", true)

            return new Promise((resolve, reject) => {
                const rootSearch = {q: rootState.search};
                const mergedQueryParams = Object.assign(rootSearch, queryParams);
                axios
                    .get(baseUrl + "/api/catalog_item_units?page=", {
                        params: mergedQueryParams,
                    })
                    .then((response) => {
                        commit("setCatalogItemUnits", response.data);
                        dispatch("clearLoading");
                        commit("setDataLoading", false)
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        dispatch("clearLoading");
                        commit("setDataLoading", false)
                        reject(error);
                    });
            });
        },
        getSingleCatalogItemUnit({commit, rootState, dispatch, state}, number) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/catalog_item_units/" + number)
                    .then((response) => {
                        commit("setCatalogItemUnit", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },

        clearSingleCatalogItemUnit({commit}){
            commit("setCatalogItemUnit", null);
        },

        getSingleCatalogItemUnitByNumber({commit, rootState, dispatch, state}, number) {
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/catalog_item_units_by_imei/" + number)
                    .then((response) => {
                        commit("setCatalogItemUnit", response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                        }
                        reject(error);
                    });
            });
        },
        addCatalogItemUnits({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/order_items/" + payload.id + "/unique_items", payload.data)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();
                        dispatch("setSnackbar", {
                            color: "green",
                            text: "Kodi pievienoti",
                        });
                        commit("clearLoading");
                        commit("removeAllFormsForDisplay");
                        dispatch("getSingleOrder", payload.order_id);

                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("clearLoading");
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Kodus neizdevās pievienot",
                            });
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        addMultipleCatalogItemUnits({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .post(baseUrl + "/api/multiple_catalog_item_units", payload.data)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();
                        commit("clearLoading");

                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            commit("clearLoading");
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        deleteCatalogItemUnit({commit, rootState, dispatch, state}, payload) {
            commit("setLoading");

            return new Promise((resolve, reject) => {
                axios
                    .delete(baseUrl + "/api/order_items/unique_items/" + payload.id)
                    .then((response) => {
                        vm.config.globalProperties.$Progress.finish();

                        dispatch("removeAllFormsForDisplay");
                        dispatch("getSingleOrder", payload.order_id);

                        dispatch("setSnackbar", {
                            color: "red",
                            text: "Ieraksts dzēsts",
                        });

                        commit("clearLoading");
                        resolve(response);
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            dispatch("destroyToken");
                            location.reload();
                        }
                        if (error.response.status === 422) {
                            vm.config.globalProperties.$Progress.fail();
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu izlabot neizdevās",
                            });
                        }

                        if (error.response.status === 403) {
                            dispatch("setSnackbar", {
                                color: "red",
                                text: "Ierakstu aizliegts dzēst",
                            });
                            vm.config.globalProperties.$Progress.finish();
                        }

                        commit("clearLoading");
                        reject(error);
                    });
            });
        },

        getCatalotItemUnitReportExcel({ commit, rootState, dispatch, state }, payload) {

            commit('setLoading')

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.auth.token
            return new Promise((resolve, reject) => {
                axios
                    .get(baseUrl + "/api/catalog_item_units_report_excel?from=" + payload.from
                        + '&to=' + payload.to
                        + '&supplier_id=' + payload.supplier_id
                        +'&q='+rootState.search,
                        {responseType: 'arraybuffer'})
                    .then((response) => {
                        // It is necessary to create a new blob object with mime-type explicitly set
                        // otherwise only Chrome works like it should
                        var newBlob = new Blob([response.data], {type: 'application/xlsx'})

                        // IE doesn't allow using a blob object directly as link href
                        // instead it is necessary to use msSaveOrOpenBlob
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob)
                            return
                        }

                        // For other browsers:
                        // Create a link pointing to the ObjectURL containing the blob.
                        const data = window.URL.createObjectURL(newBlob)
                        var link = document.createElement('a')
                        link.href = data

                        link.download = 'units_report.xlsx'

                        link.click()
                        setTimeout(function () {
                            // For Firefox it is necessary to delay revoking the ObjectURL
                            window.URL.revokeObjectURL(data)
                        }, 100)

                        commit('clearLoading')
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            dispatch('destroyToken')
                            location.reload();
                        }

                        commit('clearLoading')
                        reject(error)
                    })
            });
        },
    },
    getters: {
        catalogItemUnits(state) {
            return state.catalogItemUnits;
        },
        catalogItemUnit(state) {
            return state.catalogItemUnit;
        },
    },
};

export default catalogItemUnits;
