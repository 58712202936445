import inventoryItems from "./Inventory/inventoryItems";
import stockItems from "@/store/Stock/StockItems/StockItems";
import procurements from "@/store/Stock/Procurements/procurements";
import procurementReturnActs from "@/store/Stock/Procurements/procurementReturnActs";
import tehnicalDocuments from "@/store/Stock/TehnicalDocuments/tehnicalDocuments";
import procurementInvoices from "@/store/Stock/Procurements/procurementInovices";
import createProcurementInvoices from "@/store/Stock/Procurements/createProcurementInvoice";
import suppliers from "@/store/Stock/Suppliers/suppliers";
import supplierCategories from "@/store/Stock/Suppliers/supplierCategories";
import returnActs from "@/store/Stock/Procurements/returnActs";
import stockChecks from "@/store/Stock/StockChecks/stockChecks";
import stockReports from "@/store/Stock/Reports/stockReports";

const stock = {
  modules: {
    inventoryItems,
    stockItems,
    procurements,
    procurementReturnActs,
    tehnicalDocuments,
    procurementInvoices,
    createProcurementInvoices,
    suppliers,
    supplierCategories,
    returnActs,
    stockChecks,
    stockReports,
  },
  state: {},

  mutations: {},

  actions: {},
  getters: {},
};

export default stock;
