import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const contact = {
  state: {
    contact: null,
    discountInputData: null,
  },

  mutations: {
    setContact(state, payload) {
      state.contact = payload;
    },
  },

  actions: {
    getSingleContact({ commit, rootState, dispatch, state }, contactId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/contacts/" + contactId)
          .then((response) => {
            commit("setContact", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateContact({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/contacts/" + payload.id, payload)
          .then((response) => {
            dispatch("removeAllFormsForDisplay");
            dispatch("getSingleContact", payload.id);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setCustomerErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    addUserToContact({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .post(baseUrl + "/api/assign_user_to_contact/" + payload.id, payload.data)
            .then((response) => {
              dispatch("removeAllFormsForDisplay");
              dispatch("getSingleContact", payload.id);
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "blue",
                text: "Lietotājs pievienots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                commit("setCustomerErrorMessages", error.response.data.errors);
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Lietotāju neizdevās pievienot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },

    removeUserFromContact({ commit, rootState, dispatch, state }, contactId) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
            .delete(baseUrl + "/api/assign_user_to_contact/" + contactId)
            .then((response) => {
              dispatch("getSingleContact", contactId);
              vm.config.globalProperties.$Progress.finish();
              dispatch("setSnackbar", {
                color: "red",
                text: "Lietotājs atvienots",
              });

              commit("clearLoading");
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
                location.reload();
              }
              if (error.response.status === 422) {
                vm.config.globalProperties.$Progress.fail();
                commit("setCustomerErrorMessages", error.response.data.errors);
                dispatch("setSnackbar", {
                  color: "red",
                  text: "Lietotāju neizdevās atvienot",
                });
              }

              commit("clearLoading");
              reject(error);
            });
      });
    },

  },
  getters: {
    singleContact(state) {
      return state.contact;
    },
  },
};

export default contact;
