import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const suppliers = {
  state: {
    suppliers: null,
    supplier: null,
    supplierErrorMessages: {
      name: "",
      email: "",
    },
    fetchedSuppliers: null,
    fetchedCountries: null,
  },

  mutations: {
    setSuppliers(state, suppliers) {
      state.suppliers = suppliers;
    },
    setSupplierErrorMessages(state, errors) {
      state.supplierErrorMessages = errors;
    },
    setSingleSupplier(state, supplier) {
      state.supplier = supplier;
    },
    setFetchedSuppliers(state, suppliers) {
      state.fetchedSuppliers = suppliers;
    },
    setFetchedCountries(state, countries) {
      state.fetchedCountries = countries;
    }
  },

  actions: {
    getSuppliers({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/suppliers?page=" +
              page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setSuppliers", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    fetchSuppliers({ commit, rootState, dispatch, state }) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
            .get(baseUrl + "/api/stock/fetch_suppliers")
            .then((response) => {
              commit("setFetchedSuppliers", response.data);
              commit("setDataLoading", false)
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }
              commit("setDataLoading", false)
              reject(error);
            });
      });
    },

    fetchCountries({ commit, rootState, dispatch, state }) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
            .get(baseUrl + "/api/fetch_countries")
            .then((response) => {
              commit("setFetchedCountries", response.data);
              commit("setDataLoading", false)
              resolve(response);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                dispatch("destroyToken");
              }
              commit("setDataLoading", false)
              reject(error);
            });
      });
    },

    createNewSupplier({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/stock/suppliers", payload)
          .then((response) => {
            dispatch("getSuppliers");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setSupplierErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    getSingleSupplier({ commit, rootState, dispatch, state }, supplierId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/suppliers/" + supplierId)
          .then((response) => {
            commit("setSingleSupplier", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateSupplier({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/stock/suppliers/" + payload.id, payload)
          .then((response) => {
            dispatch("getSingleSupplier", payload.id);
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setSupplierErrorMessages", error.response.data.errors);
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteSupplier({ commit, rootState, dispatch, state }, supplierId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/stock/suppliers/" + supplierId)
          .then((response) => {
            vm.config.globalProperties.$router.push({ name: "suppliers" });
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    clearSingleSupplier({ commit }) {
      commit("setSingleSupplier", null);
    },

    clearSupplierErrorMessages({ commit }) {
      commit("setSupplierErrorMessages", {
        name: "",
        email: "",
      });
    },
  },
  getters: {
    suppliers(state) {
      return state.suppliers;
    },
    supplier(state) {
      return state.supplier;
    },
    supplierErrorMessages(state) {
      return state.supplierErrorMessages;
    },
    fetchedSuppliers(state) {
      return state.fetchedSuppliers;
    },
    fetchedCountries(state) {
      return state.fetchedCountries;
    }
  },
};

export default suppliers;
