import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const procurementInvoices = {
  state: {
    procurementInvoice: null,
    procurementInvoices: null,
  },

  mutations: {
    setProcurementInvoices(state, procurementInvoices) {
      state.procurementInvoices = procurementInvoices;
    },
    setSingleProcurementInvoice(state, procurementInvoice) {
      state.procurementInvoice = procurementInvoice;
    },
  },

  actions: {
    getProcurementInvoices({ commit, rootState, dispatch, state }, params) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/procurement_invoices?" +
              "page=" +
              params.page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setProcurementInvoices", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getSingleProcurementInvoice(
      { commit, rootState, dispatch, state },
      procurementInvoiceId
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl + "/api/stock/procurement_invoices/" + procurementInvoiceId
          )
          .then((response) => {
            commit("setSingleProcurementInvoice", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateProcurementInvoice({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl + "/api/stock/procurement_invoices/" + payload.id,
            payload
          )
          .then((response) => {
            dispatch("clearEditableItem");
            vm.config.globalProperties.$Progress.finish();

            dispatch("getSingleProcurementInvoice", payload.id);
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteProcurementInvoice({ commit, rootState, dispatch, state }, itemId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/stock/procurement_invoices/" + itemId)
          .then((response) => {
            dispatch("getProcurementInvoices", 1);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    updateProcurementInvoiceItem(
      { commit, rootState, dispatch, state },
      payload
    ) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            baseUrl +
              "/api/stock/procurement_invoices/" +
              payload.procurementInvoiceId +
              "/procurement_invoice_items/" +
              payload.procurementInvoiceItemId,
            payload.data
          )
          .then((response) => {
            dispatch(
              "getSingleProcurementInvoice",
              payload.procurementInvoiceId
            );
            dispatch("clearEditableItem");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    deleteProcurementInvoiceItem(
      { commit, rootState, dispatch, state },
      payload
    ) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            baseUrl +
              "/api/stock/procurement_invoices/" +
              payload.procurementInvoiceId +
              "/procurement_invoice_items/" +
              payload.procurementInvoiceItemId
          )
          .then((response) => {
            dispatch(
              "getSingleProcurementInvoice",
              payload.procurementInvoiceId
            );
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts izdzēsts",
            });
            resolve(response);
            vm.config.globalProperties.$Progress.finish();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    getProcurementInvoiceXml({ commit, rootState, dispatch, state }, invoice) {
      commit("setLoading");

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/procurement_invoices/xml/" + invoice.id, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response.data], {
              type: "application/xml",
            });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;
            link.download = invoice.uuid + ".xml";
            link.click();
            setTimeout(function() {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    clearSingleProcurementInvoice({ commit }) {
      commit("setSingleProcurementInvoice", null);
    },
  },
  getters: {
    procurementInvoice(state) {
      return state.procurementInvoice;
    },
    procurementInvoices(state) {
      return state.procurementInvoices;
    },
  },
};

export default procurementInvoices;
