import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const roles = {
  state: {
    roles: null,
    role: null,
    roleErrorMessages: {
      display_name: "",
    },
  },

  mutations: {
    setRoles(state, roles) {
      state.roles = roles;
    },
    setSingleRole(state, role) {
      state.role = role;
    },
    setRoleErrorMessages(state, errors) {
      state.roleErrorMessages = errors;
    },
    clearRoleErrorMessages(state) {
      state.roleErrorMessages = {
        display_name: "",
      };
    },
  },

  actions: {
    getRoles({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/roles?page=" + page + "&q=" + rootState.search)
          .then((response) => {
            commit("setRoles", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getSingleRole({ commit, rootState, dispatch, state }, roleId) {
      commit("setSingleRole", null)
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/roles/" + roleId)
          .then((response) => {
            commit("setSingleRole", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createRole({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/roles", payload)
          .then((response) => {
            dispatch("getRoles");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            commit("clearRoleErrorMessages");
            dispatch("setSnackbar", {
              color: "green",
              text: "Lietotājs izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setRoleErrorMessages", error.response.data.errors);
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateRole({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/roles/" + payload.id, payload)
          .then((response) => {
            dispatch("getSingleRole", payload.id);
            vm.config.globalProperties.$Progress.finish();
            commit("removeAllFormsForDisplay");
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              commit("setRoleErrorMessages", error.response.data.errors);
            }
            commit("clearLoading");
            reject(error);
          });
      });
    },

    addRolePermissions({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/roles/" + payload.roleId + "/permissions", {
            permissions: payload.permissions,
          })
          .then((response) => {
            dispatch("getSingleRole", payload.roleId);
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });
            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteRolePermission({ commit, rootState, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            baseUrl +
              "/api/roles/" +
              payload.roleId +
              "/permissions/" +
              payload.permissionId
          )
          .then((response) => {
            dispatch("getSingleRole", payload.roleId);
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    clearRoleErrorMessages({ commit }) {
      commit("clearRoleErrorMessages");
    },
  },
  getters: {
    role(state) {
      return state.role;
    },
    roles(state) {
      return state.roles;
    },
    roleErrorMessages(state) {
      return state.roleErrorMessages;
    },
  },
};

export default roles;
