import { vm } from "@/main";
import axios from "axios";
import catalogParamValues from "@/store/Settings/CatalogParams/catalogParamValues";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const catalogParams = {
  modules: {
    catalogParamValues,
  },

  state: {
    params: null,
    paramInputData: null,
    paramErrorMessages: {
      name: "",
    },
  },

  mutations: {
    setParams(state, params) {
      state.params = params;
    },
    setInputData(state, payload) {
      state.paramInputData = payload;
    },
    setParamErrorMessages(state, errors) {
      state.paramErrorMessages = errors;
    },
    clearParamErrorMessages(state) {
      state.paramErrorMessages = {
        name: "",
      };
    },
  },

  actions: {
    getParams({ commit, rootState, dispatch, state }, page) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/catalog_params?page=" +
              page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setParams", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getParamInputData({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/catalog_params/create")
          .then((response) => {
            commit("setInputData", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewParam({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + "/api/catalog_params", payload)
          .then((response) => {
            dispatch("getParams");
            dispatch("removeAllFormsForDisplay");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              commit("setParamErrorMessages", error.response.data.errors);
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    updateParam({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");
      return new Promise((resolve, reject) => {
        axios
          .patch(baseUrl + "/api/catalog_params/" + payload.id, payload)
          .then((response) => {
            dispatch("getParams");
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "blue",
              text: "Ieraksts izlabots",
            });

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              commit("setParamErrorMessages", error.response.data.errors);
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu neizdevās izlabot",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },
    deleteParam({ commit, rootState, dispatch, state }, paramId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/catalog_params/" + paramId)
          .then((response) => {
            dispatch("getParams");
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },
    clearParamErrorMessages({ commit }) {
      commit("clearParamErrorMessages");
    },
  },
  getters: {
    params(state) {
      return state.params;
    },
    paramInputData(state) {
      return state.paramInputData;
    },
    paramErrorMessages(state) {
      return state.paramErrorMessages;
    },
  },
};

export default catalogParams;
