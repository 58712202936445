import { vm } from "@/main";
import axios from "axios";

var baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL_PRODUCTION : process.env.VUE_APP_BASE_URL;

const returnActs = {
  state: {
    returnAct: null,
    returnActs: null,
  },

  mutations: {
    setReturnActs(state, returnActs) {
      state.returnActs = returnActs;
    },
    setSingleReturnAct(state, returnAct) {
      state.returnAct = returnAct;
    },
  },

  actions: {
    getReturnActs({ commit, rootState, dispatch, state }, params) {
      commit("setDataLoading", true)
      return new Promise((resolve, reject) => {
        axios
          .get(
            baseUrl +
              "/api/stock/return_acts?" +
              "page=" +
              params.page +
              "&q=" +
              rootState.search
          )
          .then((response) => {
            commit("setReturnActs", response.data);
            commit("setDataLoading", false)
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            commit("setDataLoading", false)
            reject(error);
          });
      });
    },

    getSingleReturnAct({ commit, rootState, dispatch, state }, returnActId) {
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/return_acts/" + returnActId)
          .then((response) => {
            commit("setSingleReturnAct", response.data);
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    createNewReturnAct({ commit, rootState, dispatch, state }, payload) {
      commit("setLoading");

      return new Promise((resolve, reject) => {
        axios
          .post(
            baseUrl + "/api/stock/return_acts/" + payload.procurementInvoiceId,
            payload.data
          )
          .then((response) => {
            vm.config.globalProperties.$Progress.finish();
            dispatch("setSnackbar", {
              color: "green",
              text: "Ieraksts izveidots",
            });
            commit("clearLoading");

            vm.config.globalProperties.$router.push({
              name: "return_acts.show",
              params: {
                returnActId: response.data.return_act_id,
              },
            });
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }
            if (error.response.status === 422) {
              vm.config.globalProperties.$Progress.fail();
              dispatch("setSnackbar", {
                color: "red",
                text: "Ierakstu izveidot neizdevās",
              });
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    getReturnActPdf({ commit, rootState, dispatch, state }, returnAct) {
      commit("setLoading");

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + rootState.auth.token;
      return new Promise((resolve, reject) => {
        axios
          .get(baseUrl + "/api/stock/return_acts/pdf/" + returnAct.id, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = data;

            link.download =
              returnAct.procurement_invoice.supplier.name +
              "_" +
              returnAct.uuid +
              ".pdf";
            link.click();
            setTimeout(function() {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);

            commit("clearLoading");
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
              location.reload();
            }

            commit("clearLoading");
            reject(error);
          });
      });
    },

    deleteReturnAct({ commit, rootState, dispatch, state }, itemId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(baseUrl + "/api/stock/return_acts/" + itemId)
          .then((response) => {
            vm.config.globalProperties.$router.push({ name: "return_acts" });
            dispatch("setSnackbar", {
              color: "red",
              text: "Ieraksts dzēsts",
            });
            vm.config.globalProperties.$Progress.finish();
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              dispatch("destroyToken");
            }
            reject(error);
          });
      });
    },

    clearSingleReturnAct({ commit }) {
      commit("setSingleReturnAct", null);
    },
  },
  getters: {
    returnAct(state) {
      return state.returnAct;
    },
    returnActs(state) {
      return state.returnActs;
    },
  },
};

export default returnActs;
